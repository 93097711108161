import { SvgIconComponent } from '@mui/icons-material'
import { ButtonProps, IconButton, Typography } from '@mui/material'

import theme from 'core/theme/theme'

interface CustomButtonProps extends ButtonProps {
    label: string
    onClick: () => void
    IconComponent?: SvgIconComponent
    customColor?: string
}
const CustomContentButton: React.FC<CustomButtonProps> = ({
    label,
    onClick,
    IconComponent,
    customColor,
    ...props
}) => {
    return (
        <IconButton
            {...props}
            onClick={onClick}
            sx={{
                borderRadius: 2,
                paddingInline: 2,
                padding: 1,
                height: 50,
                width: "100%",
                background: customColor ?? theme.COLORS.YELLOW2 ,
                ":disabled": {
                    background: theme.COLORS.GRAY4,
                    // border: `1px solid ${theme.COLORS.GRAY5}`,
                },
                transition: 'background-color 0.2s ease',
                // border: `1px solid ${theme.COLORS.YELLOW2}`,
                '&:hover': {
                    // '& .MuiSvgIcon-root': { color: 'white' },
                    // '& .MuiTypography-root': { color: 'white' },
                    backgroundColor: theme.COLORS.GRAY4,
                    // border: `1px solid ${theme.COLORS.GRAY4}`
                }
            }}
        >
            {IconComponent && (
                <IconComponent sx={{ color: props.disabled ? theme.COLORS.GRAY5 : theme.COLORS.WHITE }} />
            )}
            {/* Ícone opcional */}
            <Typography
                sx={{
                    color: props.disabled ? theme.COLORS.GRAY5 : theme.COLORS.WHITE,
                    fontSize: 18,
                    marginLeft: IconComponent ? 1 : 0
                }}
            >
                {label}
            </Typography>
        </IconButton>
    )
}
export default CustomContentButton
