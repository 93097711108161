import React from 'react';
import { Box, Button, CircularProgress, Icon, IconButton, Tooltip, Typography } from '@mui/material';
import theme from 'core/theme/theme';
import { TPaymentOption } from 'core/models/sale';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PixIcon from '@mui/icons-material/Pix';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { capitalizeFirstLetter, formatCurrencyBR, formatDateBr } from 'core/utils/globalFunctions';
import PriceTextField from 'app/components/InputPrice/PriceTextField';
import {
    ActionButtons, ActionsContainer, MainContainer, PaymentItem, PaymentItemActions,
    PaymentItemDetails, PaymentMethodContainer, PaymentModalContainer, PaymentModalFooter,
    PaymentModalHeader, PaymentOption, PaymentOptionsContainer, PaymentsListContainer, PaymentValue
} from './styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DefaultModal from '../../defaultModal/defaultModal';
import DiscountIcon from '@mui/icons-material/Discount';

interface IPayConsignedSaleContentProps {
    sale: any;
    paymentMethods: TPaymentOption[];
    paymentList: IPayment[];
    paymentValue: number | undefined;
    tempPayment: IPayment | undefined;
    loading: boolean;
    totalPayed: number;
    paying: boolean;
    paymentValueModal: boolean;
    selectedPayment: string | undefined;
    options: string[];
    handlePaySale: () => void;
    handleConfirmPayment: () => void;
    handleDeletePayment: (id: string) => void;
    handleEditPayment: () => void;
    handleCloseModal: () => void;
    handleClosePaymentModal: () => void;
    setPaymentValueModal: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedPayment: React.Dispatch<React.SetStateAction<string | undefined>>
    setPaymentValue: React.Dispatch<React.SetStateAction<number | undefined>>
    setTempPayment: any;
    totalValue: number;
    payedList?: IPayment[];
    type: 'consigned' | 'promissory';
    discountModal?: boolean;
    setDiscountModal?: React.Dispatch<React.SetStateAction<boolean>>;
    discountValue?: number | undefined;
    setDiscountValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

type IPayment = {
    id: string;
    idPayment: number;
    payment: string;
    value: number;
    canEdit?: boolean;
    datePayment: string;
    status?: string;
}

const PaymentModal = (props: IPayConsignedSaleContentProps) => {
    const {
        sale, paymentList, paymentValue, tempPayment, loading, totalPayed, type,
        paymentValueModal, selectedPayment, options, handlePaySale, handleConfirmPayment,
        handleDeletePayment, handleEditPayment, handleCloseModal, handleClosePaymentModal,
        setPaymentValueModal, setSelectedPayment, setPaymentValue, setTempPayment, totalValue, payedList,
        discountModal, setDiscountModal, discountValue, setDiscountValue
    } = props;

    return (
        <MainContainer>
            {loading ? (
                <Box sx={{
                    display: 'flex', flex: 1, height: "100%", weight: "100%", minWidth: "500px", minHeight: "300px",
                    justifyContent: "center", alignItems: "center"
                }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <PaymentMethodContainer>
                        <PaymentOptionsContainer>
                            {type === 'promissory' && setDiscountModal !== undefined &&
                                <PaymentOption onClick={() => { setDiscountModal(true) }}>
                                    <DiscountIcon sx={{ color: theme.COLORS.GREEN1 }} />
                                    <Typography sx={{ fontSize: 14, marginLeft: "0.5rem", fontWeight: "bold", color: theme.COLORS.GREEN1 }}>DESCONTO</Typography>
                                </PaymentOption>
                            }
                            {options.map((payment) => (
                                <PaymentOption sx={{
                                    display: "flex", flex: 1, flexDirection: "row", alignItems: "center",
                                    background: "rgba(200, 255, 200, 0.6)", borderRadius: "0.3rem", justifyContent: "center",
                                    padding: "0.5rem", border: `2px solid ${theme.COLORS.GREEN1}`,
                                    cursor: "pointer"
                                }} onClick={() => { setPaymentValueModal(true); setSelectedPayment(payment) }}>
                                    {payment === "DINHEIRO" && (<LocalAtmIcon sx={{ color: theme.COLORS.GREEN1 }} />)}
                                    {payment === "PIX" && (<PixIcon sx={{ color: theme.COLORS.GREEN1 }} />)}
                                    {payment === "BOLETO" && (<ReceiptIcon sx={{ color: theme.COLORS.GREEN1 }} />)}
                                    {payment === "CREDITO" && (<CreditCardIcon sx={{ color: theme.COLORS.GREEN1 }} />)}
                                    {payment === "DÉBITO" && (<CreditScoreIcon sx={{ color: theme.COLORS.GREEN1 }} />)}
                                    <Typography sx={{ fontSize: 14, marginLeft: "0.5rem", fontWeight: "bold", color: theme.COLORS.GREEN1 }}>{payment}</Typography>
                                </PaymentOption>
                            ))}
                        </PaymentOptionsContainer>

                    </PaymentMethodContainer>

                    <PaymentsListContainer sx={{ justifyContent: paymentList.length && !payedList ? "center" : undefined }}>
                        {payedList && (
                            payedList.map((payment) => (
                                <PaymentItem>
                                    <PaymentItemDetails>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{payment.payment}</Typography>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{formatCurrencyBR(payment.value)}</Typography>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{payment.status}</Typography>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{formatDateBr(payment.datePayment)}</Typography>
                                    </PaymentItemDetails>
                                    <PaymentItemActions>
                                        <IconButton disabled>
                                            <EditIcon sx={{ color: theme.COLORS.GRAY3 }} />
                                        </IconButton>
                                        <IconButton disabled>
                                            <DeleteIcon sx={{ color: theme.COLORS.GRAY3 }} />
                                        </IconButton>
                                    </PaymentItemActions>
                                </PaymentItem>
                            ))
                        )}
                        {paymentList.length === 0 && !paymentList ? <Typography sx={{ fontSize: 14, minWidth: 60, textAlign: "center", fontWeight: "bold" }}>
                            Nenhum pagamento cadastrado</Typography>
                            : paymentList.map((payment) => (
                                <PaymentItem>
                                    <PaymentItemDetails>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{payment.payment}</Typography>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{formatCurrencyBR(payment.value)}</Typography>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{payment.status}</Typography>
                                        <Typography sx={{ fontSize: 14, minWidth: 60 }}>{formatDateBr(payment.datePayment)}</Typography>
                                    </PaymentItemDetails>
                                    <PaymentItemActions>
                                        <IconButton onClick={() => { setTempPayment(payment); setPaymentValueModal(true); setPaymentValue(payment.value) }}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => { handleDeletePayment(payment.id) }} sx={{ color: theme.COLORS.RED }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </PaymentItemActions>
                                </PaymentItem>
                            ))}
                    </PaymentsListContainer>

                    <ActionsContainer>
                        <Button sx={{ width: "25%" }} onClick={handleCloseModal}>voltar</Button>
                        <Button sx={{ width: "25%" }} onClick={handlePaySale} disabled={
                            discountValue ? (totalPayed + discountValue) !== totalValue : totalPayed !== totalValue
                        }>Pagar</Button>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-start",  width: "250px" }}>
                            {sale.discount &&
                                <PaymentValue sx={{ fontWeight: "bold", color: theme.COLORS.RED, textAlign: "start" }}>
                                    Desconto: {formatCurrencyBR(sale.discount)}
                                </PaymentValue>}

                            {totalPayed === 0 && discountValue === undefined || discountValue === 0
                                ? <PaymentValue sx={{ fontWeight: "bold", textAlign: "start" }}>Valor total:  {totalValue ? formatCurrencyBR(totalValue) : formatCurrencyBR(sale.amount)}</PaymentValue> :
                                <PaymentValue sx={{ fontWeight: "bold", textAlign: "start" }}>Valor restante: {discountValue ? formatCurrencyBR(totalValue - totalPayed - discountValue) : formatCurrencyBR(totalValue - totalPayed)}</PaymentValue>}
                            {discountValue && discountValue > 0 && setDiscountValue &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                                    <PaymentValue sx={{ fontWeight: "bold", color: theme.COLORS.RED, textAlign: "start" }}>Valor descontado: {formatCurrencyBR(discountValue)}</PaymentValue>
                                    <Tooltip title="Retirar desconto">
                                        <IconButton onClick={() => { setDiscountValue(undefined) }} sx={{ color: theme.COLORS.RED }}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                        </Box>

                    </ActionsContainer>

                    {paymentValueModal && <DefaultModal
                        title=''
                        isOpen={paymentValueModal}
                        onClose={() => handleClosePaymentModal}
                        onOpen={() => setPaymentValueModal(true)}
                        children={
                            <PaymentModalContainer>
                                <PaymentModalHeader>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            minWidth: 60,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: theme.COLORS.GREEN1,
                                        }}
                                    >
                                        Pagamento {capitalizeFirstLetter(selectedPayment)}
                                    </Typography>

                                    <PaymentValue sx={{ fontSize: 14, fontWeight: "bold" }}>
                                        {totalPayed === 0 && discountValue === undefined
                                            ? `Valor total: ${formatCurrencyBR(totalValue ?? sale.amount)}`
                                            : `Valor restante: ${formatCurrencyBR(
                                                (totalValue ?? sale.amount) - totalPayed - (discountValue ?? 0)
                                            )}`}
                                    </PaymentValue>
                                </PaymentModalHeader>

                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                                    <PriceTextField
                                        name='paymentValue'
                                        label='Valor'
                                        value={String(paymentValue)}
                                        handleFormattedChange={(e) => {
                                            const newValue = Number(e.replace(/\D/g, "")) / 100;
                                            setPaymentValue(newValue);
                                        }}
                                        priceNumber={paymentValue}
                                        setPriceNumber={setPaymentValue}
                                    />
                                </Box>
                                <PaymentModalFooter>
                                    <Button sx={{ width: "41%" }} onClick={() => { setPaymentValueModal(false); setPaymentValue(undefined); }}>Cancelar</Button>
                                    <Button sx={{ width: "41%" }} onClick={tempPayment ? handleEditPayment : handleConfirmPayment} disabled={paymentValue === 0 || selectedPayment === undefined || paymentValue === undefined}>Confirmar</Button>
                                </PaymentModalFooter>
                            </PaymentModalContainer>
                        }
                    />}
                    {discountModal !== undefined && setDiscountModal !== undefined &&
                        discountValue !== 0 && setDiscountValue &&
                        <DefaultModal
                            title=''
                            isOpen={discountModal}
                            onClose={() => { setDiscountModal(false); setDiscountValue(undefined) }}
                            onOpen={() => setDiscountModal(true)}
                            children={
                                <PaymentModalContainer>
                                    <PaymentModalHeader>
                                        <Typography sx={{ fontSize: 14, minWidth: 60, textAlign: "center", fontWeight: "bold", color: theme.COLORS.GREEN1 }}>Desconto</Typography>
                                    </PaymentModalHeader>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                                        <PriceTextField
                                            name='discountValue'
                                            label='Valor'
                                            value={String(discountValue)}
                                            handleFormattedChange={(e) => {
                                                const newValue = Number(e.replace(/\D/g, "")) / 100;
                                                setDiscountValue(newValue === 0 ? undefined : newValue);
                                            }}
                                            priceNumber={discountValue}
                                            setPriceNumber={setDiscountValue}
                                        />
                                    </Box>
                                    <PaymentModalFooter>
                                        <Button sx={{ width: "41%" }} onClick={() => { setDiscountModal(false); setDiscountValue(undefined); }}>Cancelar</Button>
                                        <Button sx={{ width: "41%" }} onClick={() => setDiscountModal(false)} disabled={discountValue === 0 || discountValue === undefined}>Confirmar</Button>
                                    </PaymentModalFooter>
                                </PaymentModalContainer>
                            }
                        />}
                </>
            )}
        </MainContainer>
    );
};

export default PaymentModal;
