import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Validations } from "core/utils/validations";
import { useCallback, useState } from "react";
import { LoginContainer, LoginContentBox, LoginLogo } from "../styles";
import { useFormik } from "formik";
import { formatDocument, removeNonNumeric } from "core/utils/globalFunctions";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GenericTextField from "app/components/genericTextField/GenericTextField";
import { sendMessegeService } from "core/api/user/userService";
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from "react-router-dom";

type TForgotPassword = {
  cpf: string;
  email: string
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: TForgotPassword = {
    cpf: "",
    email: "",
  };

  const submitUserToResetPassword = useCallback(
    async (cpf: string, email: string) => {
      setIsLoading(true);
      await sendMessegeService(cpf, email)
        .then(() => {setIsLoading(false);  navigate('/login')})
        .catch(() => setIsLoading(false))
    },
    []
  );

  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    validationSchema: Validations.forgotPasswordSchema,
    onSubmit: () => {
      let cpfCleaned = removeNonNumeric(formik.values.cpf)
      localStorage.setItem("redefineKey", cpfCleaned!)
      submitUserToResetPassword(String(cpfCleaned), formik.values.email);

    },
  });

  return (
    <LoginContainer
      sx={{ display: "flex" }}
      $small={isSmallScreen}
      maxWidth={isSmallScreen ? "sm" : "md"}
      disableGutters
    >
      <LoginLogo $small={isSmallScreen}></LoginLogo>

      <LoginContentBox onSubmit={formik.handleSubmit} $small={isSmallScreen}>
        <Typography
          sx={{
            fontSize: "1.1pc",
            fontWeight: "bold",
            marginTop: isSmallScreen ? 0 : "-20px",
          }}
        >
          ESQUECI MINHA SENHA
        </Typography>
        <Typography
          sx={{
            fontSize: "0.9pc",
            marginTop: isSmallScreen ? 0 : "-5x",
          }}
        >
          Será enviado um link para o email de cadastro, por favor acessar link.
        </Typography>
        <Box sx={{display: "flex", flexDirection: "column", gap: 2, width: "100%"}}>
          <GenericTextField<string>
            props={{
              fullWidth: true,
              onChange: formik.handleChange,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineIcon />
                  </InputAdornment>
                ),
              },
            }}
            onBlur={() => {
              formik.setFieldValue("cpf", formatDocument(formik.values.cpf));
            }}
            value={formik.values.cpf}
            label="CPF/CNPJ"
            name="cpf"
            error={!!formik.errors.cpf}
            helperText={formik.errors.cpf}
            style={{ marginBottom: -5 }}
            onChangeManual={(e: string) =>
              formik.setFieldValue('cpf', e)
            }
          />
          <GenericTextField<string>
            props={{
              fullWidth: true,
              onChange: formik.handleChange,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailIcon />
                  </InputAdornment>
                ),
              },
            }}
            value={formik.values.email}
            label="Email"
            name="email"
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            style={{ marginBottom: -5 }}
            onChangeManual={(e: string) =>
              formik.setFieldValue('email', e)
            }
          />
        </Box>


        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={isLoading}
            type={"submit"}
            sx={{
              fontWeight: "bold",
              borderRadius: 1,
              position: "relative",
              height: "45px",
            }}
            fullWidth
            variant="contained"
          >
            <Typography>ENVIAR</Typography>
          </Button>
        )}
        <Typography sx={{ fontSize: "0.8pc", marginTop: 6 }}>V 2.0</Typography>
      </LoginContentBox>
    </LoginContainer>
  );
};

export default ForgotPassword;
