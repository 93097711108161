import {
    TClientBody,
    TClientPageable,
    TClientRegister,
    TClientUpdate
} from 'core/models/clientLBS'
import { IPage, IResponseBody } from 'core/models/utils'
import { axiosInstance } from '../axios/axiosInstance'
import {
    CLIENT_ACTVATE_UPDATE,
    CLIENT_DELETE,
    CLIENT_DETAILS,
    CLIENT_LIST,
    CLIENT_SALE,
    CLIENT_SALE_DETAILS,
    CLIENT_SAVE,
    CLIENT_UPDATE,
    SALES_LIST
} from 'core/utils/constants'
import { Notification } from 'app/components/toastNotification/toastNotification'
import axios, { AxiosError } from 'axios'
import {
    TSaleClientItems,
    TSaleContent,
    TSalesPageable
} from 'core/models/sales'

const getFiltedClient = async (
    clientPageable: TClientPageable
): Promise<IPage<any> | undefined> => {
    const response = await axiosInstance.get<IResponseBody<IPage<any>>>(
        CLIENT_LIST,
        {
            params: {
                page: clientPageable.page,
                size: clientPageable.size,
                sort: 'createdAt,desc',
                name: clientPageable?.name,
                cpforcnpj: clientPageable?.cpforcnpj
            }
        }
    )
    return response.data.data
}

const getClientSales = async (
    clientId: number,
    page: number,
    size: number,
    cpf?: string
): Promise<IPage<TSaleContent> | undefined> => {
    const response = await axiosInstance.get<
        IResponseBody<IPage<TSaleContent>>
    >(SALES_LIST, {
        params: {
            // clientId: clientId,
            clientcpforcnpj: cpf,
            page: page,
            size: size,
            sort: 'createdAt,desc'
        }
    })
    return response.data.data
}

const getClientSalesItems = async (
    salesId: number,
    page: number,
    size: number
): Promise<IPage<TSaleClientItems> | undefined> => {
    const response = await axiosInstance.get<
        IResponseBody<IPage<TSaleClientItems>>
    >(CLIENT_SALE_DETAILS, {
        params: {
            salesId: salesId,
            page: page,
            size: size,
            sort: 'createdAt,desc'
        }
    })
    return response.data.data
}

interface ValidationError {
    message: string
    errors: Record<string, string[]>
}

const updateClient = async (
    updatedClient: any,
    idClient: string
): Promise<any> => {
    return await axiosInstance
        .put(`${CLIENT_UPDATE}${idClient}`, updatedClient)
        .then((resp) => {
            Notification(resp.data.data, 'success')
            return resp
        })
        .catch((error) => {
            Notification(String(error.message), 'error')
            return error
        })
}

const deleteClient = async (idClient: string): Promise<any> => {
    return await axiosInstance
        .delete(`${CLIENT_DELETE}${idClient}`)
        .then((resp) => {
            Notification(resp.data.data, 'success')
            return resp
        })
        .catch((error) => {
            return error
        })
}

const createClient = async (newClient: TClientRegister): Promise<any> => {
    return await axiosInstance
        .post(`${CLIENT_SAVE}`, newClient)
        .then((response) => {
            if (response instanceof AxiosError) {
                return response.data.errors
            }
            Notification(response.data.data, 'success')
            return response
        })
}

const clientDetails = async (
    idClient: string
): Promise<IResponseBody<TClientBody>> => {
    return await axiosInstance
        .get<IResponseBody<TClientBody>>(`${CLIENT_DETAILS}${idClient}`)
        .then((resp) => {
            return resp.data.data
        })
        .catch((error) => {
            return error
        })
}

const activateClient = async (idClient: number): Promise<any> => {
    return await axiosInstance
        .put(`${CLIENT_ACTVATE_UPDATE}${idClient}`)
        .then((resp) => {
            Notification(resp.data.data, 'success')
            return resp
        })
        .catch((error) => {
            Notification(error.response, 'error')
            return error
        })
}

export const ClientLbsService = {
    getFiltedClient,
    updateClient,
    deleteClient,
    createClient,
    clientDetails,
    getClientSales,
    getClientSalesItems,
    activateClient
}
