import Add from '@mui/icons-material/Add'
import { useQuery } from '@tanstack/react-query'
import { PageContentContainer } from 'app/components/styles'
import DataTablePagination from 'app/components/table/pagination/pagination'
import DataTable from 'app/components/table/table/table'
import TableHeader from 'app/components/table/tableHeader/TableHeader'
import { ITableHeadCell, Order } from 'core/models/table'
import { fecthClients } from 'core/querryes/client/clientLbsQuerry'
import theme from 'core/theme/theme'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import Search from 'app/components/search/Search'
import { ISelectItem } from 'app/components/filter/defaultFilter'
import DefaultMenu, { IMenuItemProps } from 'app/components/menu/DefaultMenu'
import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded'
import ClientDetailsModal from 'app/components/modals/detailsModal/ClientDetailsModal'
import { ContentBody } from 'app/styles'
import EditClientModal from '../editers/editClient'
import { removeNonNumeric } from 'core/utils/globalFunctions'
import NewDefaultFilter from 'app/components/filter/newDefaultFilter'
import { TClientBody } from 'core/models/clientLBS'
import { ClientLbsService } from 'core/api/client/clientService'
import { Notification } from 'app/components/toastNotification/toastNotification'

const head: ITableHeadCell[] = [
    { name: 'name', label: 'Nome', align: 'left' },
    { name: 'rg', label: 'RG', align: 'left' },
    { name: 'cpforcnpj', label: 'CPF / CNPJ', align: 'left' },
    { name: 'createdAt', label: 'Criado em', align: 'left' },
    { name: 'indication', label: 'Indicação', align: 'left' },
    { name: 'active', label: 'Ativo', align: 'left' },
    { name: 'actions2', label: 'Opções', align: 'left' }
]

const filterItems: ISelectItem[] = [
    { name: 'Nome', value: 'name', type: 'texto' },
    { name: 'CPF / CNPJ', value: 'cpforcnpj', type: 'texto' }
]

function Client() {
    const navigate = useNavigate()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [idClient, setIdClient] = useState<string | undefined>()
    const [details, setDetails] = useState(false)
    const [filterModal, setFilterModal] = useState(false)
    const [tempClient, setTempClient] = useState<TClientBody | undefined>()

    const [isOpen, setIsOpen] = useState(false)

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const menuItems: IMenuItemProps[] = [
        {
            function: () => {
                setDetails(true)
                handleCloseMenu()
            },
            label: 'Detalhes do cliente'
        },
        {
            function: () => {
                setIsOpen(true)
                handleCloseMenu()
            },
            label: 'Editar cliente'
        },
        ...(tempClient
            ? [
                {
                    function: async () => {
                        await ClientLbsService.activateClient(
                            tempClient?.id
                        ).then((resp) => {
                            Notification(resp, 'success')
                            refetch()
                        })
                        handleCloseMenu()
                    },
                    label: tempClient?.active ? 'Desativar cliente' : 'Ativar cliente'
                }
            ]
            : [])
    ]

    const [searchParams, setSearchParams] = useSearchParams()
    const name = searchParams.get('name') || ''
    const cpforcnpj = searchParams.get('cpforcnpj') || ''
    const { data, isLoading, isSuccess, refetch } = useQuery({
        queryKey: [
            'clients',
            page,
            rowsPerPage,
            orderBy,
            order,
            cpforcnpj,
            name
        ],
        queryFn: () =>
            fecthClients(page, rowsPerPage, orderBy, order, name, cpforcnpj)
    })

    const handleCpfCnpjChange = (value: string | undefined) => {
        const newParams = new URLSearchParams(searchParams)
        if (value) {
            newParams.set('cpforcnpj', removeNonNumeric(value) ?? '')
        } else {
            newParams.delete('cpforcnpj')
        }
        setSearchParams(newParams)
    }

    useEffect(() => {
        if (isSuccess && data) {
            setCount(data.totalElements)
        }
    }, [isSuccess, data])

    const handleAccessRowById = (id: string) => {
        setTempClient(data?.content.find((client: any) => client.id === id))
    }

    return (
        <PageContentContainer>
            <TableHeader
                filterBtn
                filterBtnAction={() => setFilterModal(true)}
                mainActionFunction={() => navigate('/registrarCliente')}
                mainActionLabel="Cadastrar cliente"
                mainIcon={<Add sx={{ color: theme.COLORS.YELLOW2 }} />}
                extraComponents={
                    <Search
                        querry={cpforcnpj}
                        type="number"
                        searchPlaceHolder="Cpf ou cnpj do cliente..."
                        querrySearching={isLoading}
                        onChange={handleCpfCnpjChange}
                    />
                }
            />
            <ContentBody>
                <DataTable
                    head={head}
                    data={data?.content}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    isLoading={isLoading}
                    menu={
                        <Tooltip title="Opções">
                            <IconButton
                                onClick={(event) => {
                                    handleClickMenu(event)
                                }}
                            >
                                <MoreHorizRounded />
                            </IconButton>
                        </Tooltip>
                    }
                    accessRowById={handleAccessRowById}
                />

                <DefaultMenu
                    anchor={anchorEl}
                    menuItems={menuItems}
                    onClose={handleCloseMenu}
                    status={open}
                />
                {tempClient && details && (
                    <ClientDetailsModal
                        client={tempClient}
                        isOpen={details}
                        onClose={() => setDetails(false)}
                        onOpen={() => setDetails(true)}
                    />
                )}
                <DataTablePagination
                    setPage={setPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                />
                <NewDefaultFilter
                    isOpen={filterModal}
                    items={filterItems}
                    onClose={() => setFilterModal(false)}
                    onOpen={() => setFilterModal(true)}
                    title="Filtrar Clientes"
                    changePage={setPage}
                />
                {tempClient && isOpen && (
                    <EditClientModal
                        isOpen={isOpen}
                        refetch={refetch}
                        setIsOpen={setIsOpen}
                        onClose={() => setIsOpen(false)}
                        onOpen={() => setIsOpen(true)}
                        client={tempClient}
                    />
                )}
            </ContentBody>
        </PageContentContainer>
    )
}

export default Client
