import React, { useEffect, useMemo, useState } from 'react'
import DefaultModal from '../../defaultModal/defaultModal'
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import theme from 'core/theme/theme';
import { axiosInstance } from 'core/api/axios/axiosInstance';
import { IPage, IResponseBody } from 'core/models/utils';
import { TPaymentOption } from 'core/models/sale';
import { LIST_PAYMENT_TYPES } from 'core/utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { SalesService, TPayments } from 'core/api/sales/salesService';
import { AxiosError } from 'axios';
import { Notification } from 'app/components/toastNotification/toastNotification';
import { TSaleContent } from 'core/models/sales';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import PaymentModal from './paymentModal';

interface IPaySaleModalProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    sale: TSaleContent;
    refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<IPage<any>, Error>>
}

type IPayment = {
    id: string;
    idPayment: number;
    payment: string;
    value: number;
    canEdit?: boolean;
    datePayment: string;
    status?: string;
}

const PayPromissorySale = (props: IPaySaleModalProps) => {
    const { isOpen, onClose, onOpen, sale, refetch } = props;
    const [paymentMethods, setPaymentMethods] = useState<TPaymentOption[]>([]);
    const [options, setOptions] = useState<string[]>([]);
    const loading = isOpen && paymentMethods.length === 0;
    const [paymentList, setPaymentList] = useState<IPayment[]>([]);
    const [payedList, setPayedList] = useState<IPayment[]>([]);
    const [paymentValueModal, setPaymentValueModal] = useState(false);
    const [paymentValue, setPaymentValue] = useState<number | undefined>(0);
    const [selectedPayment, setSelectedPayment] = useState<string | undefined>();
    const [tempPayment, setTempPayment] = useState<IPayment | undefined>();
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [discountModal, setDiscountModal] = useState(false);
    const [discountValue, setDiscountValue] = useState<number | undefined>(undefined);



    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            try {
                const response = await axiosInstance.get<
                    IResponseBody<IPage<TPaymentOption>>
                >(LIST_PAYMENT_TYPES, {
                    params: {
                        sort: `name,desc`,
                    },
                });
                if (active) {
                    const paymentOptions = response.data.data.content;
                    setPaymentMethods(paymentOptions);
                    const filteredOptions = paymentOptions
                        .filter((item) => item.name !== "PROMISSÓRIA" && item.name !== "CONSIGNADO")
                        .map((item) => item.name);

                    setOptions(filteredOptions);
                }
            } catch (error) {
                console.error("Erro ao buscar dados: ", error);
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);


    useEffect(() => {
        if (sale.saleId && isOpen) {
            SalesService.getSaleDetails(String(sale.saleId))
                .then((response) => {
                    const newPaymentList = response.data.data.salesPaymentMethods
                        .filter((payment: any) => payment.paymentMethod.name !== "PROMISSÓRIA")
                        .map((payment: any) => ({
                            id: payment.id,
                            idPayment: payment.paymentMethod.id,
                            payment: payment.paymentMethod.name,
                            value: payment.value,
                            canEdit: false,
                            status: "Pago",
                            datePayment: payment.createdAt
                        }));
                    setPayedList(newPaymentList);
                })
                .catch((error) => {
                    console.error("Erro ao buscar dados: ", error);
                });
        }
    }, [sale]);


    const handlePaySale = () => {
        setLoadingPayment(true);
        const paymentMethodDTOList: TPayments[] = [];
        paymentList.forEach((payment) => {
            paymentMethodDTOList.push({
                id: payment.idPayment,
                value: payment.value,
            });
        });
        if (discountValue && discountValue > 0) {
            SalesService.payPromissorySale(sale.saleId, { paymentMethodDTOList, typediscount: "FIXED", discount: discountValue })
                .then((resp) => {
                    setPaymentList([]);
                    setLoadingPayment(false);
                    onClose();
                    refetch();
                })
                .catch((err: AxiosError) => {
                    setLoadingPayment(false);
                    Notification(String(err.message), "error");
                });
        }
        else {
            SalesService.payPromissorySale(sale.saleId, paymentMethodDTOList)
                .then((resp) => {
                    setPaymentList([]);
                    setLoadingPayment(false);
                    onClose();
                    refetch();
                })
                .catch((err: AxiosError) => {
                    setLoadingPayment(false);
                    Notification(String(err.message), "error");
                });
        }
    };

    const getIdByPaymentName = (payment: string | undefined) => {
        switch (payment) {
            case "DINHEIRO":
                return 5;
            case "PIX":
                return 1;
            case "BOLETO":
                return 2;
            case "CREDITO":
                return 7;
            case "DÉBITO":
                return 6;

            default:
                return undefined;
        }
    };

    const totalPayed = useMemo(() => {
        const totalListPayment = paymentList.reduce((acc, curr) => acc + curr.value, 0);
        const totalPayed = payedList.reduce((acc, curr) => acc + curr.value, 0);
        return totalListPayment + totalPayed;

    }, [paymentList, payedList]);


    const handleConfirmPayment = () => {
        const isDuplicatePayment = paymentList.some(payment => payment.payment === selectedPayment);
        if (isDuplicatePayment) {
            Notification("Método de pagamento já adicionado.", "warning");
            return;
        }

        const newPayment: IPayment = {
            id: uuidv4(),
            idPayment: getIdByPaymentName(selectedPayment) || 0,
            payment: selectedPayment || "",
            value: paymentValue || 0,
            canEdit: true,
            datePayment: new Date().toISOString(),
            status: "Em andamento",
        };
        setPaymentList([...paymentList, newPayment]);
        setPaymentValue(undefined);
        setPaymentValueModal(false);
    };

    const handleDeletePayment = (id: string) => {
        setPaymentList(paymentList.filter((item) => item.id !== id));
    };

    const handleEditPayment = () => {
        const newPayment: IPayment = {
            id: tempPayment?.id!!,
            idPayment: getIdByPaymentName(tempPayment?.payment!!) || 0,
            payment: tempPayment?.payment!! || "",
            value: paymentValue || 0,
            canEdit: true,
            datePayment: new Date().toISOString(),
            status: "Em andamento",
        };
        setPaymentList(paymentList.map((item) => item.id === tempPayment?.id ? newPayment : item));
        setPaymentValue(undefined);
        setPaymentValueModal(false);
        setTempPayment(undefined);
    };

    const handleClosePaymentModal = () => {
        setPaymentValueModal(false);
        setPaymentValue(undefined);
        setTempPayment(undefined);
        setSelectedPayment(undefined);
    };

    const handleCloseModal = () => {
        onClose();
        setPaymentList([]);
        setPaymentValue(undefined);
        setTempPayment(undefined);
        setSelectedPayment(undefined);
    };

    return (
        <DefaultModal
            title=""
            isOpen={isOpen}
            onClose={handleCloseModal}
            onOpen={onOpen}
            children={
                <PaymentModal
                    totalValue={sale.amount}
                    sale={sale}
                    paymentMethods={paymentMethods}
                    paymentList={paymentList}
                    paymentValue={paymentValue}
                    tempPayment={tempPayment}
                    loading={loading}
                    paying={loadingPayment}
                    totalPayed={totalPayed}
                    paymentValueModal={paymentValueModal}
                    selectedPayment={selectedPayment}
                    options={options}
                    handlePaySale={handlePaySale}
                    handleConfirmPayment={handleConfirmPayment}
                    handleDeletePayment={handleDeletePayment}
                    handleEditPayment={handleEditPayment}
                    handleCloseModal={handleCloseModal}
                    handleClosePaymentModal={handleClosePaymentModal}
                    setPaymentValueModal={setPaymentValueModal}
                    setSelectedPayment={setSelectedPayment}
                    setPaymentValue={setPaymentValue}
                    setTempPayment={setTempPayment}
                    payedList={payedList}
                    type="promissory"
                    discountModal={discountModal}
                    setDiscountModal={setDiscountModal}
                    discountValue={discountValue}
                    setDiscountValue={setDiscountValue}
                />
            }
        />
    )
}

export default PayPromissorySale