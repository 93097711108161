import { TCollaboratorBody } from "core/models/collaborator";
import DefaultModal from "../defaultModal/defaultModal";
import { Container, InfoValue } from "./styles";
import { InfoCard, InfoCardContainer, InfoCardTitle, InfoKey, InfoRow, InfosSection } from "app/components/styles";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { capitalizeFirstLetter, formatCurrencyBR, formatDateBr, formatDocument, formatPhoneNumber, formatRG, formatarCEP } from "core/utils/globalFunctions";
import { SectionCol } from "core/theme/globalStyles";
import { IInfoProps, InfosComponent } from "./ClientDetailsModal";
import { CollaboratorService } from "core/api/collaborator/collaboratorService";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Order } from "core/models/table";
import VisibilityIcon from '@mui/icons-material/Visibility'
import DataTablePagination from "app/components/table/pagination/pagination";
import { ClientLbsService } from "core/api/client/clientService";
import Spinner from "app/components/spinner/spinner";

interface ICollaboratorDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    collaborator: TCollaboratorBody;
}

const CollaboratorDetailsModal = (props: ICollaboratorDetailsProps) => {
    const { isOpen, onClose, onOpen, collaborator } = props;
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [showSalesItems, setShowSalesItems] = useState(false)
    const [tempSaleid, setTempSaleid] = useState<number | undefined>()

    const [itemsRowsPerPage, setItemsRowsPerPage] = useState(10)
    const [itemsPage, setItemsPage] = useState(0)
    const [itemsCount, setItemsCount] = useState(0)
    const [itemsOrder, setItemsOrder] = useState<Order>('asc')

    // ? requisicao para as compras do colaborador
    const collaboratorSales = useQuery({
        queryKey: ['collaboratorSales', collaborator.cpforcnpj],
        queryFn: () =>
            CollaboratorService.getCollaboratorSales(
                page,
                rowsPerPage,
                collaborator.cpforcnpj,
            ),
        staleTime: Infinity
    })

    // ? requisição para os itens de cada compra do colaborador, condicional à existência de tempSaleid
    const collaboratorItems = useQuery({
        queryKey: ['collaboratorItems', tempSaleid],
        queryFn: () =>
            tempSaleid
                ? ClientLbsService.getClientSalesItems(
                    tempSaleid,
                    itemsPage,
                    itemsRowsPerPage
                )
                : Promise.resolve(null),
        staleTime: Infinity,
        enabled: !!tempSaleid
    })

    const collaboratorPersonalInfos: IInfoProps[] = [
        { key: 'Nome', value: collaborator.name },
        { key: 'RG', value: collaborator.rg, formater: formatRG },
        {
            key: 'CPF / CNPJ',
            value: collaborator.cpforcnpj,
            formater: formatDocument
        },
        {
            key: 'Data de nascimento',
            value: collaborator.birthDate,
            formater: formatDateBr
        },
        { key: 'Criado em', value: collaborator.createdAt, formater: formatDateBr },
        {
            key: 'Ultima atualização',
            value: collaborator.updateAt,
            formater: formatDateBr
        }
    ]

    const collaboratoraddressInfos: IInfoProps[] = [
        { key: 'CEP', value: collaborator?.address?.zipCode, formater: formatarCEP },
        { key: 'Estado', value: collaborator?.address?.uf },
        { key: 'Cidade', value: collaborator?.address?.city },
        { key: 'Bairro', value: collaborator?.address?.neighborhood },
        { key: 'Rua', value: collaborator?.address?.road },
        { key: 'Número', value: collaborator?.address?.number }
    ]

    const collaboratorcontactsInfos: IInfoProps[] = [
        {
            key: 'Celular principal',
            value: collaborator?.contacts?.cellPhone,
            formater: formatPhoneNumber
        },
        {
            key: 'Celular secundário',
            value: collaborator?.contacts?.cellPhone2,
            formater: formatPhoneNumber
        },
        {
            key: 'Telefone',
            value: collaborator?.contacts?.telephone,
            formater: formatPhoneNumber
        },
        { key: 'Email', value: collaborator?.contacts?.email }
    ]

    useEffect(() => {
        if (collaboratorSales.isSuccess && collaboratorSales.data) {
            setCount(collaboratorSales.data.totalElements)
        }
        if (collaboratorItems.isSuccess && collaboratorItems.data) {
            setItemsCount(collaboratorItems.data.totalElements)
        }
    }, [
        collaboratorSales.isSuccess,
        collaboratorSales.data,
        collaboratorItems.isSuccess,
        collaboratorItems.data
    ])


    return (
        <>
            <DefaultModal
                title="Detalhes do Colaborador"
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                children={
                    <SectionCol sx={{ gap: 2 }}>
                        <InfosComponent
                            items={[
                                {
                                    title: 'Informações gerais',
                                    infos: collaboratorPersonalInfos
                                },
                                {
                                    title: 'Endereço',
                                    infos: collaboratoraddressInfos
                                },

                                {
                                    title: 'Contatos',
                                    infos: collaboratorcontactsInfos
                                }
                            ]}
                        />
                        {collaboratorSales.data?.content && (
                            <TableContainer
                                sx={{ maxHeight: 300, position: 'relative' }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Vendedor</TableCell>
                                            <TableCell align="right">
                                                Pagamento
                                            </TableCell>
                                            <TableCell align="right">
                                                Status
                                            </TableCell>
                                            <TableCell align="right">
                                                Código
                                            </TableCell>
                                            <TableCell align="right">
                                                Tipo
                                            </TableCell>
                                            <TableCell align="right">
                                                Data
                                            </TableCell>
                                            <TableCell align="right">Items</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {collaboratorSales.data?.content.length ===
                                            0 ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    align="center"
                                                >
                                                    Nenhuma venda encontrada
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            collaboratorSales.data?.content.map(
                                                (row) => (
                                                    <TableRow key={row.saleId}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {
                                                                row.nameCollaborator
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.paymentMethod}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.status}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.saleCod}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.type}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {formatDateBr(row.data)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowSalesItems(
                                                                        true
                                                                    )
                                                                    setTempSaleid(
                                                                        row.saleId
                                                                    )
                                                                }}
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <DataTablePagination
                            setPage={setPage}
                            page={page}
                            setRowsPerPage={setRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            count={count}
                        />
                    </SectionCol>
                }
            />
            <DefaultModal
                title="Items da venda"
                isOpen={showSalesItems}
                onClose={() => setShowSalesItems(false)}
                onOpen={() => setShowSalesItems(true)}
            >
                <TableContainer sx={{ minHeight: 300, position: 'relative', maxHeight: 350 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="left">Nome</TableCell>
                                <TableCell align="left">Descrição</TableCell>
                                <TableCell align="right">Qtd</TableCell>
                                <TableCell align="right">Valor Unit.</TableCell>
                                <TableCell align="right">Valor total</TableCell>
                            </TableRow>
                        </TableHead>
                        {collaboratorItems.isLoading ? (
                            <Spinner
                                state={collaboratorSales.isLoading}
                                size={10}
                                css={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%'
                                }}
                            />
                        ) : (
                            <TableBody>
                                {collaboratorItems.data?.content.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            Nenhum item encontrado
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    collaboratorItems.data?.content.map(
                                        (row) => (
                                            <TableRow key={row.productId}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.productId}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.productName}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        row.characteristicsDescription
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.itemSalesAmount}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {formatCurrencyBR(
                                                        row.unitaryValue
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {formatCurrencyBR(
                                                        row.itemSalesAmount *
                                                        row.unitaryValue
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )
                                )}
                            </TableBody>
                        )}
                    </Table>
                    <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
                        <DataTablePagination
                            setPage={setItemsPage}
                            page={itemsPage}
                            setRowsPerPage={setItemsRowsPerPage}
                            rowsPerPage={itemsRowsPerPage}
                            count={itemsCount}
                        />
                    </Box>
                </TableContainer>
            </DefaultModal>
        </>
    )
}

export default CollaboratorDetailsModal