import * as React from "react";
import html2pdf from "html2pdf.js";
import { AxiosError } from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import {
  LIST_PAYMENT_TYPES,
  MAKE_SALE,
  PRODUCT_IMAGE,
} from "core/utils/constants";
import { axiosInstance } from "core/api/axios/axiosInstance";
import { formatCurrencyBR, removeNonNumeric } from "core/utils/globalFunctions";
import { RootState } from "core/redux/store";
import {
  RESET_LIST,
  RESET_PAYMENT_METHOD,
  RESET_PAYMENT_TO_SALE,
  SET_CHANGE_VALUE,
  SET_DISABLED_OPTIONS,
  SET_FIXED_DISCOUNT,
  SET_NAME,
  SET_PERCENTAGE_DISCOUNT,
  SET_SALE_REQUEST,
  setPaymentMethodNames,
  setPaymentToSale,
} from "core/redux/slices/saleSlice/saleSlice";
import theme from "../../../core/theme/theme";
import { TPaymentOption } from "../../../core/models/sale";
import { IPage, IResponseBody } from "../../../core/models/utils";
import { TPaymentMethodSale, TSaleDiscounts } from "app/views/sale/sale";
import { CupomFiscal } from "../cupomFiscal/CupomFiscal";
import DefaultDialog from "../defaultDialog/defaultDialog";
import PriceTextField from "../InputPrice/PriceTextField";
import { useAppSelector } from "../../../core/hooks/reduxHooks";
import { Notification } from "../toastNotification/toastNotification";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { useMediaQuery } from "@mui/material";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { useSale } from "core/context/SaleInfoContext";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: "90svh",
  overflowY: 'scroll'
};

export const SaleInfos = () => {
  const {
    clearSale,
    receivedCash,
    setReceivedCash,
    boletoState,
    setBoletoState,
    pixState,
    setPixState,
    debitoState,
    setDebitoState,
    creditoState,
    setCreditoState,
    moneyState,
    setMoneyState,
    promissoriaState,
    setPromissoriaState,
    consignadoState,
    setConsignadoState,
    fixedDiscountState,
    setFixedDiscountState,
  } = useSale();
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  const {
    saleRequest,
    list,
    autoCompleteNames,
    paymentMethodNames,
    paymentToSale,
    percentageDiscount,
    fixedDiscount,
    salePriceType,
    disabledOptions
  } = useSelector((state: RootState) => state.sale);

  const isHeightGreaterThan720 = useMediaQuery("(min-height:720px)");
  const isHeightGreaterThan820 = useMediaQuery("(min-height:800px)");
  const isHeightGreaterThan920 = useMediaQuery("(min-height:900px)");
  const isHeightGreaterThan1080 = useMediaQuery("(min-height:1000px)");
  // verifica se tem dinheiro selecionado como método de pagamento
  const haveMoneyPayment = () => {
    if (paymentToSale.some((payment) => payment.name === "DINHEIRO"))
      return true;
    else
      return false;
  }

  // ? valor total da compra
  const totalValue = useMemo(
    () =>
      list.reduce(
        (acc, item) =>
          salePriceType === "ATACADO"
            ? acc + (item.resalePrice ?? 0) * item.quantity
            : acc + (item.priceTag ?? 0) * item.quantity,
        0
      ),
    [list, salePriceType]
  );

  // ! verifica se o valor pago é maior ou igual ao valor da venda,
  // caso tenha dinheiro selecionado ele deixa o valor pago passar o total da compra
  const canPassValue = useCallback((totalPayments: number, finalValue: number) => {

    if (haveMoneyPayment()) {
      return totalPayments >= parseFloat(finalValue.toFixed(2));
    }
    else {
      return totalPayments === parseFloat(finalValue.toFixed(2));
    }
  }, [paymentToSale, fixedDiscount, percentageDiscount]);


  // ? Verifica se a compra pode ser finalizada
  const canFinishSale = useMemo(() => {

    const discountAmount = parseFloat((totalValue * (percentageDiscount / 100)).toFixed(2));

    const finalValue = parseFloat((totalValue - discountAmount - fixedDiscount).toFixed(2));

    const totalPayments = parseFloat((paymentToSale.reduce(
      (acc, item) => acc + (item.value ?? 0),
      0
    )).toFixed(2));

    // console.log("desconto fixo:" + fixedDiscount);
    // console.log("desconto amount?:" + discountAmount);
    // console.log("valor total da compra com desconto:" + finalValue);
    // console.log("valor total dos pagamentos:" + totalPayments);

    const haveConsignedOrPromissory = paymentToSale.some((payment) => payment.name === "CONSIGNADO" || payment.name === "PROMISSÓRIA");

    return (
      (totalValue >= 0 // * valor da venda maior que 0
        && canPassValue(totalPayments, finalValue)// * valor pago maior ou igual que valor da venda
        && paymentToSale.every((item) => item.value >= 0) // * todos os métodos de pagamento tem valor maior que 0
        && finalValue >= 0 // * valor com desconto não pode ser negativo
        && saleRequest.clientId !== 0 // * cliente não pode ser nulo
        && saleRequest.collaboratorId !== 0 // * colaborador não pode ser nulo
        && totalValue > 0 // * valor total não pode ser negativo
      ) || (
        haveConsignedOrPromissory
        && finalValue >= 0 // * valor com desconto não pode ser negativo
        && saleRequest.clientId !== 0 // * cliente não pode ser nulo
        && saleRequest.collaboratorId !== 0 // * colaborador não pode ser nulo
        && totalValue > 0 // * valor total não pode ser negativo

      )
    );
  }, [totalValue, percentageDiscount, fixedDiscount, paymentToSale, saleRequest]);

  const [productImage, setproductImage] = useState("");
  const [isFinishingSale, setFinishSale] = useState(false);

  // ! teste
  const [showCupom, setShowCupom] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // ! guarda os metodos de pagamento vindos da api
  const [paymentMethods, setPaymentMethods] = useState<TPaymentOption[]>([]);

  // ! guarda os nomes dos metodos de pagamento como opcao e selecionados
  const [options, setOptions] = useState<string[]>([]);


  const [openImage, setOpenImage] = useState(false);

  const handleOpen = () => {
    setOpenImage(true);
  };

  const handleClose = () => {
    setOpenImage(false);
  };


  useEffect(() => {
    console.log("saleRequest: ", saleRequest);
    console.log("autoCompleteNames: ", autoCompleteNames);
    console.log("paymentToSale: ", paymentToSale);
    console.log("paymentMethodNames: ", paymentMethodNames);
    console.log("paymentMethods: ", paymentMethods);
    console.log("percentageDiscount: ", percentageDiscount);
    console.log("fixedDiscount: ", fixedDiscount);
    console.log("salePriceType: ", salePriceType);
    console.log("disabledOptions: ", disabledOptions);
  }, [saleRequest, autoCompleteNames, paymentToSale, paymentMethodNames, paymentMethods, percentageDiscount, fixedDiscount, salePriceType, disabledOptions]);

  const totalPayments = useMemo(() => {
    return paymentToSale.reduce(
      (acc, item) => acc + (item.value ?? 0),
      0)
  }, [paymentToSale, paymentMethods, paymentMethodNames]
  );


  // ! Desabilita metodos de pagamento dependendo do metodo selecionado
  useEffect(() => {
    if (paymentToSale.length === 0) {
      dispatch({
        type: SET_DISABLED_OPTIONS,
        payload: []
      });
    }
    else {
      paymentToSale.map((payment) => {
        if (payment.name === "CONSIGNADO") {
          dispatch({
            type: SET_DISABLED_OPTIONS,
            payload: options.filter(option => option !== "CONSIGNADO")
          });
        }
        else if (payment.name === "PROMISSÓRIA") {
          dispatch({
            type: SET_DISABLED_OPTIONS,
            payload: options.filter(option => option !== "PROMISSÓRIA")
          });
        }
        else {
          dispatch({
            type: SET_DISABLED_OPTIONS,
            payload: ["CONSIGNADO", "PROMISSÓRIA"]
          });
        }
      })
    }
  }, [paymentToSale, paymentMethodNames]);


  const handleChange = (event: SelectChangeEvent<typeof paymentMethodNames>) => {
    const { target: { value } } = event;
    const selectedNames = typeof value === "string" ? value.split(",") : value;
    dispatch(setPaymentMethodNames(selectedNames));

    let methods = paymentMethods.filter((item) =>
      selectedNames.includes(item.name)
    );

    // ? iniciar o array de métodos de pagamento para a venda
    let updateMethods: TPaymentMethodSale[] = [];

    // ? para cada método de pagamento, verificar se ele está selecionado
    methods.forEach((method) => {
      if (selectedNames.includes(method.name)) {
        updateMethods.push({
          id: method.id,
          name: method.name,
          // ! se não encontrar o método de pagamento, colocar 0 como valor
          value:
            parseFloat(paymentToSale.find((item) => item.id === method.id)?.value.toFixed(2) || "0"),
        });
      }
    });

    // ? atualizar o estado da venda
    dispatch(setPaymentToSale(updateMethods));
  };



  // ? carregar a imagem do ultimo produto adicionado
  useEffect(() => {
    const lastProduct = list[list.length - 1];
    if (lastProduct?.image.length === 0) {
      return;
    }
    const fetchImage = async () => {
      if (lastProduct?.image) {
        const response = await axiosInstance.get(
          `${PRODUCT_IMAGE}${lastProduct.image[0].id}`
        );
        setproductImage(response.data.data);
      } else {
        setproductImage("");
      }
    };
    fetchImage();
  }, [list]);


  // ! verifica qual desconto está sendo usado
  function verifyDiscount(): number {
    if (percentageDiscount > 0) {
      return percentageDiscount;
    } else if (fixedDiscount > 0) {
      return fixedDiscount;
    } else {
      return 0;
    }
  };

  // ! verifica qual tipo de desconto está sendo usado
  function verifyTypeDiscount(): TSaleDiscounts {
    if (percentageDiscount === 0 && fixedDiscount > 0) {
      return "FIXED";
    } else if (percentageDiscount > 0 && fixedDiscount === 0) {
      return "PERCENTAGE";
    }
    else {
      return null;
    }
  };

  const [saleCode, setSaleCode] = useState<string | undefined>();

  // ? enviar a compra
  const submitSale = async () => {
    setFinishSale(true);
    const newSale: any = {
      collaboratorId: saleRequest.collaboratorId,
      clientId: saleRequest.clientId,
      caixaId: basicUserInfo?.id,
      typediscount: verifyTypeDiscount(),
      discount: verifyDiscount(),
      typeItemSales: salePriceType,
      amount:
        parseFloat((percentageDiscount > 0
          ? totalValue * (1 - percentageDiscount / 100)
          : totalValue - fixedDiscount).toFixed(2)),
      paymentMethodDTOs: paymentToSale,
      productDTOs: list.map((item) => ({
        productId: item.id!,
        amount: item.quantity,
        productCharacteristcsDTOs: item.caracteristicsRequest,
      })),
    };

    await axiosInstance
      .post(`${MAKE_SALE}`, newSale)
      .then((resp) => {
        // Mostrar o Cupom Fiscal
        setSaleCode(resp.data.data);
        setFinishSale(false);
        setShowCupom(true);
      })
      .catch((err: AxiosError) => {
        setFinishSale(false);
        Notification(String(err.message), "error");
      });
  };

  const lastProduct = list[list.length - 1];

  // ! teste
  const loading = open && paymentMethods.length === 0;

  // ! carregar os metodos de pagamento quando abrir o select
  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      try {
        const response = await axiosInstance.get<
          IResponseBody<IPage<TPaymentOption>>
        >(LIST_PAYMENT_TYPES, {
          params: {
            sort: `name,desc`,
          },
        });
        if (active) {
          setPaymentMethods(response.data.data.content);
          setOptions(response.data.data.content.map((item) => item.name));
        }
      } catch (error) {
        console.error("Erro ao buscar dados: ", error);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, page, rowsPerPage]);

  // ! atualizar o valor do desconto fixo no input
  useEffect(() => {
    const fixedDiscountAtual = fixedDiscount;
    if (fixedDiscountAtual) {
      setFixedDiscountState(fixedDiscountAtual);
    }
  }, [fixedDiscount]);

  // ! atualizar os valores dos pagamentos no input
  useEffect(() => {
    const boletoPayment = paymentToSale.find(
      (payment) => payment.name === "BOLETO"
    );
    const pixPayment = paymentToSale.find((payment) => payment.name === "PIX");
    const dinheiroPayment = paymentToSale.find((payment) => payment.name === "DINHEIRO");
    const promissoriaPayment = paymentToSale.find((payment) => payment.name === "PROMISSÓRIA");
    const consignadoPayment = paymentToSale.find((payment) => payment.name === "CONSIGNADO");
    const debitoPayment = paymentToSale.find((payment) => payment.name === "DÉBITO");
    const creditoPayment = paymentToSale.find((payment) => payment.name === "CREDITO");


    if (boletoPayment) setBoletoState(parseFloat(boletoPayment.value.toFixed(2)));
    if (pixPayment) setPixState(parseFloat(pixPayment.value.toFixed(2)));
    if (dinheiroPayment) setMoneyState(parseFloat(dinheiroPayment.value.toFixed(2)));
    if (promissoriaPayment) setPromissoriaState(parseFloat(promissoriaPayment.value.toFixed(2)));
    if (consignadoPayment) setConsignadoState(parseFloat(consignadoPayment.value.toFixed(2)));
    if (debitoPayment) setDebitoState(parseFloat(debitoPayment.value.toFixed(2)));
    if (creditoPayment) setCreditoState(parseFloat(creditoPayment.value.toFixed(2)));

  }, [paymentToSale]);

  // ! alterar valor de cada método de pagamento
  const handleLocalPaymentChange = (id: number, newValue: number) => {
    dispatch(
      setPaymentToSale(
        paymentToSale.map((p) =>
          p.id === id
            ? {
              ...p,
              value: parseFloat(newValue.toFixed(2)),
            }
            : p
        )
      )
    );
  };



  useEffect(() => {
    haveMoneyPayment()
  }, [paymentToSale]);

  // ! alterar desconto fixo
  const handleChangeFixedDiscount = (value: number) => {
    dispatch({ type: SET_FIXED_DISCOUNT, payload: value });
  };

  // ! alterar desconto percentual
  const handleChangePercentageDiscount = (value: string) => {
    let parsedToNumber = value === "" ? 0 : Number(removeNonNumeric(value));
    dispatch({ type: SET_PERCENTAGE_DISCOUNT, payload: parsedToNumber });
  };

  // calcula valor do troco
  useEffect(() => {
    const totalPayed = paymentToSale.reduce(
      (acc, item) => acc + (item.value ?? 0),
      0
    );
    const valuePay = parseFloat((percentageDiscount > 0
      ? totalValue * (1 - percentageDiscount / 100)
      : totalValue - fixedDiscount).toFixed(2));

    setReceivedCash(totalPayed - valuePay);
  }, [paymentToSale, percentageDiscount, fixedDiscount, totalValue]);

  const handlePageRemainingPayment = (paymentName: string, id: number) => {
    // Encontre o pagamento atual
    const currentPayment = paymentToSale.find((payment) => payment.id === id);

    if (!currentPayment) return;

    // Calcule o valor atual já atribuído a outros métodos de pagamento
    const otherPaymentsTotal = paymentToSale.reduce((acc: number, payment: TPaymentMethodSale) => {
      if (payment.id !== id) {
        return acc + payment.value;
      }
      return acc;
    }, 0);

    // Calcule o novo valor baseado no valor restante
    const newValue = totalValue - otherPaymentsTotal - fixedDiscount - ((percentageDiscount / 100) * totalValue);

    // Atualize o estado específico do método de pagamento
    switch (paymentName) {
      case "DINHEIRO":
        setMoneyState(newValue);
        break;
      case "PROMISSÓRIA":
        setPromissoriaState(newValue);
        break;
      case "CONSIGNADO":
        setConsignadoState(newValue);
        break;
      case "PIX":
        setPixState(newValue);
        break;
      case "BOLETO":
        setBoletoState(newValue);
        break;
      case "DÉBITO":
        setDebitoState(newValue);
        break;
      case "CREDITO":
        setCreditoState(newValue);
        break;
      default:
        break;
    }

    // Atualize o pagamento na lista
    const updatedPayments = paymentToSale.map((payment: TPaymentMethodSale) =>
      payment.id === id ? { ...payment, value: parseFloat(newValue.toFixed(2)) } : payment
    );

    // Despache a ação para atualizar o estado global via Redux
    dispatch(setPaymentToSale(updatedPayments));
  };

  const handleCloseCoupon = () => {
    setShowCupom(false);
    clearSale();
  };


  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        minWidth: 300,
        gap: "0.5rem",
      }}
    >
      <Box
        sx={{
          flex: 1,
          minHeight: 270,
          background: theme.COLORS.WHITE,
          borderRadius: "0.5rem",
          padding: "0.5rem",
          border: `2px solid ${theme.COLORS.GRAY5}`,
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        {lastProduct ? (
          <>
            {productImage === "" ? (
              <Skeleton variant="rounded" width={"100%"} height={150} />
            ) : (
              <Box
                bgcolor={theme.COLORS.GRAY6}
                display={"flex"}
                width={"100%"}
                justifyContent={"center"}
                onClick={handleOpen}
                sx={{ cursor: "pointer" }}
              >
                <img
                  height={150}
                  style={{ objectFit: "contain" }}
                  src={`data:image/jpeg;base64,${productImage}`}
                  alt="product"
                />
              </Box>
            )}
            <Typography textAlign={"center"} fontWeight={"bold"}>
              {lastProduct?.productName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "start",
                }}
              >
                <Typography sx={{ fontSize: 14 }}>
                  <span style={{ fontWeight: "bold", fontSize: 14 }}>No carrinho: </span>{" "}
                  {lastProduct?.quantity}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  <span style={{ fontWeight: "bold", fontSize: 14 }}>Preço revenda: </span>
                  {formatCurrencyBR(lastProduct?.resalePrice)}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  <span style={{ fontWeight: "bold", fontSize: 14 }}>Preço varejo: </span>
                  {formatCurrencyBR(lastProduct?.priceTag)}
                </Typography>
              </Box>
              {lastProduct.caracteristicsInfos.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "start",
                    maxHeight: 70,
                    overflowY: "scroll",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                    Caracteristicas:
                  </Typography>
                  {lastProduct?.caracteristicsRequest.map((caracteristic) => (
                    <Typography key={caracteristic.id} sx={{ fontSize: 14 }}>
                      {
                        lastProduct.caracteristicsInfos.find(
                          (item) => item.id === caracteristic.id
                        )?.description
                      }{" "}
                      -{" "}
                      {
                        lastProduct.caracteristicsRequest.find(
                          (item) => item.id === caracteristic.id
                        )?.amountProductCharacteristics
                      }
                      x
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>

            {/* Dialog para visualizar a imagem */}
            <Dialog open={openImage} onClose={handleClose} maxWidth="md">
              <DialogContent>
                <img
                  src={`data:image/jpeg;base64,${productImage}`}
                  alt="product"
                  style={{ width: 600, height: 600, overflow: "hidden" }}
                />
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>

            <RemoveShoppingCartIcon sx={{ color: theme.COLORS.GRAY5, width: 50, height: 50 }} />
          </Box>

        )}
      </Box>
      <Box
        sx={{
          flex: 3,
          background: theme.COLORS.WHITE,
          borderRadius: "0.5rem",
          padding: "0.5rem",
          border: `2px solid ${theme.COLORS.GRAY5}`,
          display: "flex",
          height: "100%",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "45%" }}>
            <Typography textAlign={"center"} fontWeight={"bold"}>
              Desconto
            </Typography>
            <TextField
              disabled={fixedDiscount > 0}
              variant="outlined"
              label={"R$ %"}
              value={percentageDiscount === 0 ? "" : percentageDiscount}
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 3) {
                  handleChangePercentageDiscount(value);
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
              size="small"
            />
            <PriceTextField
              disabled={percentageDiscount > 0}
              name="fixedDiscount"
              label="R$ fixo"
              value={fixedDiscount === 0 ? "" : fixedDiscount.toString()}
              handleFormattedChange={(e) => {
                const newValue = Number(e.replace(/\D/g, "")) / 100;
                handleChangeFixedDiscount(newValue);
              }}
              priceNumber={fixedDiscountState}
              setPriceNumber={setFixedDiscountState}
              maxLength={8}

            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"bold"}>Valor da compra</Typography>
            <Typography fontWeight={"bold"} fontSize={28}>
              {formatCurrencyBR(
                percentageDiscount > 0
                  ? totalValue * (1 - percentageDiscount / 100)
                  : totalValue - fixedDiscount
              )}
            </Typography>

            {haveMoneyPayment() && receivedCash > 0 && <Typography fontSize={15} color="red">
              Troco: {formatCurrencyBR(receivedCash)}
            </Typography>}
          </Box>
        </Box>

        <Divider />

        <FormControl size={"small"}>
          <InputLabel>Método de pagamento</InputLabel>
          <Select
            onOpen={() => setOpen(true)}
            multiple
            value={paymentMethodNames}
            onChange={handleChange}
            input={<OutlinedInput label="Método de pagamento" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {options.map((name, index) => (
              <MenuItem key={name} value={name} disabled={disabledOptions?.includes(name)}>
                <Checkbox
                  checked={paymentMethodNames.includes(name)}
                  disabled={loading}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* seção dos pagamentos */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            gap: "0.5rem",
            overflowY: "auto",
            maxHeight: isHeightGreaterThan1080 ? "45svh" :
              isHeightGreaterThan920 ? "30svh" :
                isHeightGreaterThan820 ? "20svh" :
                  isHeightGreaterThan720 ? "10svh" : "8svh",
            padding: "0.5rem",
          }}
        >
          {/* formas de pagamento */}
          {/* alterar o valor de cada método de pagamento */}
          {paymentToSale.map((payment) => (
            <Box
              key={payment.id}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  flex: 1,
                  fontWeight: "bold",
                  fontSize: 14,
                  minWidth: 65,
                }}
              >
                {payment.name}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", alignItems: "center", width: "100%" }}>
                <PriceTextField
                  name="paymentValue"
                  label={"Valor Pago"}
                  disabled={payment.name === "PROMISSÓRIA" || payment.name === "CONSIGNADO"}
                  style={{ width: "70%", marginLeft: "1rem" }}
                  value={payment.value === 0 ? "" : payment.value.toString()}
                  handleFormattedChange={(e) => {
                    const newValue = Number(e.replace(/\D/g, "")) / 100;
                    handleLocalPaymentChange(payment.id, newValue);
                  }}
                  setPriceNumber={
                    payment.name === "BOLETO" ? setBoletoState : payment.name === "PIX" ? setPixState : payment.name === "DINHEIRO" ?
                      setMoneyState : payment.name === "PROMISSÓRIA" ? setPromissoriaState : payment.name === "DÉBITO" ? setDebitoState :
                        payment.name === "CREDITO" ? setCreditoState : setConsignadoState
                  }
                  priceNumber={payment.name === "BOLETO" ? boletoState : payment.name === "PIX" ? pixState : payment.name === "DINHEIRO" ?
                    moneyState : payment.name === "PROMISSÓRIA" ? promissoriaState : payment.name === "DÉBITO" ? debitoState :
                      payment.name === "CREDITO" ? creditoState : consignadoState}
                />
              </Box>
              <Tooltip title="Completar valor">
                <IconButton onClick={() => handlePageRemainingPayment(payment.name, payment.id)} disabled={payment.name === "PROMISSÓRIA" || payment.name === "CONSIGNADO"}>
                  <PriceCheckIcon sx={{ color: theme.COLORS.GREEN1 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Excluir pagamento">
                <IconButton
                  sx={{ width: 30, height: 30 }}
                  onClick={() => {
                    const remainingPaymentToSale = paymentToSale.filter(
                      (item) =>
                        item.id !== payment.id && item.name !== payment.name
                    );
                    const remainingPaymentMethodNames = paymentMethodNames.filter(
                      (name) => name !== payment.name
                    );
                    dispatch(setPaymentToSale(remainingPaymentToSale));
                    dispatch(setPaymentMethodNames(remainingPaymentMethodNames));

                    // Limpar o estado específico com base no nome do pagamento
                    switch (payment.name) {
                      case 'PROMISSÓRIA':
                        setPromissoriaState(undefined);
                        break;
                      case 'CONSIGNADO':
                        setConsignadoState(undefined);
                        break;
                      case 'PIX':
                        setPixState(undefined);
                        break;
                      case 'DINHEIRO':
                        setMoneyState(undefined);
                        break;
                      case 'BOLETO':
                        setBoletoState(undefined);
                        break;
                      case 'DÉBITO':
                        setDebitoState(undefined);
                        break;
                      case 'CREDITO':
                        setCreditoState(undefined);
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <DeleteIcon sx={{ color: theme.COLORS.RED }} />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
        </Box>

        <Typography fontWeight={"bold"} fontSize={18} sx={{ textAlign: "center", marginTop: "auto" }}>
          <Divider style={{ marginBottom: "0.5rem" }} />
          Total Pago: {formatCurrencyBR(totalPayments)}
        </Typography>
      </Box>
      <Button disabled={!canFinishSale || isFinishingSale} onClick={() => submitSale()}>
        Finalizar Compra
      </Button>

      <Modal
        open={showCupom}
        onClose={handleCloseCoupon}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {saleCode ?
          (<Box sx={style}>
            <CupomFiscal saleCode={saleCode} />
          </Box>) : <></>}
      </Modal>
    </Box>
  );
};

// variaveis do select multiplo
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
