import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { axiosInstance } from "core/api/axios/axiosInstance";
import { IPage, IResponseBody } from "core/models/utils";
import { debounce, FormHelperText } from "@mui/material";

interface IAsyncProps<T> {
  handleOnSelect: (selected: T) => void;
  urlToSearch: string;
  label: string;
  sortField?: "name" | "nameRepresentative" | "createdAt" | "sku" | "indication";
  style?: React.CSSProperties | undefined;
  error?: string;
  haveError?: boolean;
  variant?: "standard" | "outlined" | "filled" | undefined;
  onClear?: () => void;
  selectedValue?: T | null;
  searchParamName: string;
  active?: string;
  optionsToKeyDown?: any[];
  setOptionsToKeyDown?: React.Dispatch<React.SetStateAction<any[]>>;
  optionsToSearch?: any[];
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>, nextRef: React.RefObject<HTMLElement>) => void;
  setLoadingOptions?: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef?: React.RefObject<HTMLElement>;
}

type OptionType = Partial<{
  name: string;
  nameCompany: string;
  sku: string;
  indication: string;
  amount: number;
}>;

export default function AsyncAutoComplete<T extends OptionType>({
  handleOnSelect,
  urlToSearch,
  label,
  sortField,
  style,
  error,
  haveError,
  variant,
  onClear,
  selectedValue,
  searchParamName,
  active,
  optionsToKeyDown,
  setOptionsToKeyDown,
  onKeyDown,
  setLoadingOptions,
  optionsToSearch,
  inputRef
}: IAsyncProps<T>) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly T[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchOptions = React.useCallback(
    debounce(async (value: string) => {
      setIsLoading(true);
      if (setLoadingOptions) {
        setLoadingOptions(true);
      }
      try {
        const params: Record<string, any> = {
          page: 0,
          size: 100,
          sort: `${sortField},desc`,
          [searchParamName]: value ?? "",
        };

        if (active !== undefined) {
          params[active] = true;
        }

        const response = await axiosInstance.get<IResponseBody<IPage<T>>>(urlToSearch, { params });
        setOptions(response.data.data.content);
        if (setOptionsToKeyDown) {
          setOptionsToKeyDown(response.data.data.content);
        }
      } catch (error) {
        console.error("Erro ao buscar dados: ", error);
      } finally {
        setIsLoading(false);
        if (setLoadingOptions) {
          setLoadingOptions(false);
        }
      }
    }, 300),
    [urlToSearch, sortField, searchParamName, active]
  );

  React.useEffect(() => {
    if (open) {
      fetchOptions(inputValue);
    }
  }, [open, inputValue, fetchOptions]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      if (setOptionsToKeyDown) {
        setOptionsToKeyDown([]);
      }
    }
  }, [open]);

  const getOptionLabel = (option: T): string => {
    return option.sku ?? option.name ?? option.nameCompany ?? option.indication ?? "";
  };

  const isOptionEqualToValue = (option: T, value: T): boolean => {
    if (!option || !value) return false;
    return (
      option.sku === value.sku ||
      option.name === value.name ||
      option.nameCompany === value.nameCompany ||
      option.indication === value.indication
    );
  };

  React.useEffect(() => {
    console.log("inputValue: ", inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
      fullWidth
      value={selectedValue}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event: any, value: T | null) => {
        if (value) {
          handleOnSelect(value);
          console.log("value: ", value);
          console.log("event: ", event);
        } else if (onClear) {
          onClear();
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, value) => setInputValue(value)}
      renderOption={(props, option) => (
        <li {...props}>
          {"sku" in option && "name" in option
            ? `Código: ${option.sku} - Nome: ${option.name} - Qtd: ${option.amount ?? 0}`
            : getOptionLabel(option)}
        </li>
      )}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      noOptionsText={"Nenhum resultado encontrado"}
      options={optionsToSearch ?? options}
      loading={isLoading}
      style={style}
      loadingText={"Procurando..."}
      autoFocus
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          size={"small"}
          autoFocus
          fullWidth
          style={style}
          label={label}
          error={haveError}
          variant={variant}
          helperText={
            haveError && (
              <FormHelperText sx={{ margin: -0.5, padding: 0 }} error={haveError}>
                {String(error)}
              </FormHelperText>
            )
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onKeyDown={(e) => {
            // Verificando se o inputRef é definido antes de passá-lo
            if (onKeyDown && inputRef && inputRef.current) {
              onKeyDown(e, inputRef); // Passando o inputRef corretamente
            }
          }}
        />
      )}
    />
  );
}
