import { Add } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import DefaultMenu, {
    IMenuItemProps
} from '../../../app/components/menu/DefaultMenu'
import Search from '../../../app/components/search/Search'
import { PageContentContainer } from '../../../app/components/styles'
import TableHeader from '../../../app/components/table/tableHeader/TableHeader'
import { Order } from '../../../core/models/table'
import { fecthSales } from '../../../core/querryes/sales/salesQuerry'
import theme from '../../../core/theme/theme'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material'
import Spinner from '../../../app/components/spinner/spinner'
import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded'
import DataTablePagination from '../../../app/components/table/pagination/pagination'
import { ContentBody } from '../../../app/styles'
import { TSaleContent, TSalesFilterRequest } from '../../../core/models/sales'
import SalesDetailsModal from '../../../app/components/modals/detailsModal/saleDetails/SaleDetailsModal'
import { StyledDivDataTable } from 'app/components/table/tableHead/styles'
import { SalesCell, SalesTypography } from './styles'
import {
    formatBigNameSales,
    formatCurrencyBR,
    formatDateBr,
    formatDocument,
    removeNonNumeric
} from 'core/utils/globalFunctions'
import PayPromissorySale from 'app/components/modals/detailsModal/sales/payPromisorrySale'
import NewDefaultFilter from 'app/components/filter/newDefaultFilter'
import PayConsignedSale from 'app/components/modals/detailsModal/sales/payConsignedSale'
import { SalesService } from 'core/api/sales/salesService'
import DefaultDialog from 'app/components/defaultDialog/defaultDialog'
import StyledStatus from 'app/components/styledStatus/styledStatus'

const filterItems: any[] = [
    { name: 'CPF do cliente', value: 'clientcpforcnpj', type: 'texto' },
    { name: 'Codigo da venda', value: 'salesCod', type: 'texto' },
    {
        name: 'Status',
        value: 'status',
        type: 'genericStatus',
        options: [
            { key: 'Pendente', value: 'PENDENTE' },
            { key: 'Pago', value: 'PAGO' }
        ]
    },
    {
        name: 'Método de pagamento',
        value: 'mtd',
        type: 'genericStatus',
        options: [
            { key: 'Dinheiro', value: 'DINHEIRO' },
            { key: 'Pix', value: 'PIX' },
            { key: 'Cartão de crédito', value: 'CREDITO' },
            { key: 'Cartão de débito', value: 'DEBITO' },
            { key: 'Promossória', value: 'PROMISSÓRIA' },
            { key: 'Consignado', value: 'CONSIGNADO' }
        ]
    }
]

const Sales = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [filterModal, setFilterModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [details, setDetails] = useState(false)
    const [idSale, setIdSale] = useState<string | undefined>()
    const [salesFilters, setSalesFilters] = useState<TSalesFilterRequest>({
        clientcpforcnpj: '',
        status: undefined,
        clientId: undefined,
        salesCod: undefined
    })
    const [paymentConsignadoModal, setPaymentConsignadoModal] = useState(false)
    const [paymentPromissoriaModal, setPaymentPromissoriaModal] =
        useState(false)
    const [tempSale, setTempSale] = useState<TSaleContent>()
    const [isCanceling, setIsCanceling] = useState(false)
    const [openCancelConsignedSale, setOpenCancelConsignedSale] = useState(false)
    const [openCancelSale, setOpenCancelSale] = useState(false)

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleAccessRowById = (id: string) => {
        setIdSale(id)
    }

    // ? verifica se a venda (row) é consignada
    const checkItemsConsignado = (names: string | undefined | null) => {
        if (names === undefined || names === null) return false
        const items = names.split(',')
        return items.some((item) => item.includes('CONSIGNADO'))
    }

    // ? verifica se a venda (row) é promissória
    const checkItemsPromissoria = (names: string | undefined | null) => {
        if (names === undefined || names === null) return false
        const items = names.split(',')
        return items.some((item) => item.includes('PROMISSÓRIA'))
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const cpforcnpj = searchParams.get('clientcpforcnpj') || ''
    const status = searchParams.get('status') || ''
    const salesCod = searchParams.get('salesCod') || ''

    const { data, isLoading, isSuccess, refetch } = useQuery({
        queryKey: [
            'sales',
            page,
            rowsPerPage,
            orderBy,
            order,
            salesFilters,
            cpforcnpj,
            status,
            salesCod
        ],
        queryFn: () =>
            fecthSales(
                page,
                rowsPerPage,
                orderBy,
                order,
                cpforcnpj,
                status,
                salesFilters.clientId,
                salesCod
            )
    })

    const selectedSale = useMemo(() => {
        return data?.content.find((sale: any) => sale.saleId === Number(idSale))
    }, [data, idSale])

    const handleCancelSale = async (idSale: string) => {
        setIsCanceling(true)
        await SalesService.cancelSale(idSale).then((resp) => {
            refetch()
            setIsCanceling(false)
            setOpenCancelSale(false)
        })
        setIsCanceling(false)
        setOpenCancelSale(false)
    }

    const callCancelSale = () => {
        handleCancelSale(String(tempSale?.saleId))
    }

    const handleCancelConsignedSale = async (idSale: string) => {
        setIsCanceling(true)
        await SalesService.cancelConsignedSale(idSale).then((resp) => {
            refetch()
            setIsCanceling(false)
            setOpenCancelSale(false)
        })
        setIsCanceling(false)
        setOpenCancelSale(false)
    }

    const callCancelConsignedSale = () => {
        handleCancelConsignedSale(String(tempSale?.saleId))
    }

    const menuItems: IMenuItemProps[] = [
        {
            function: () => {
                setDetails(true)
                handleCloseMenu()
            },
            label: 'Detalhes da venda'
        },

        ...(checkItemsConsignado(tempSale?.paymentMethod) &&
            tempSale?.status === 'PENDENTE'
            ? [
                {
                    function: () => {
                        setPaymentConsignadoModal(true)
                        handleCloseMenu()
                    },
                    label: 'Pagar consignado'
                }
            ]
            : [
                ...(checkItemsPromissoria(tempSale?.paymentMethod) &&
                    tempSale?.status === 'PENDENTE'
                    ? [
                        {
                            function: () => {
                                setPaymentPromissoriaModal(true)
                                handleCloseMenu()
                            },
                            label: 'Pagar promissória'
                        }
                    ]
                    : [])
            ]),
        ...(checkItemsConsignado(tempSale?.paymentMethod) &&
            tempSale?.status === 'PENDENTE'
            ? [
                {
                    function: () => {
                        setOpenCancelSale(true)
                        handleCloseMenu()
                    },
                    label: 'Cancelar consignado'
                }
            ]
            : [{
                function: () => {
                    setOpenCancelSale(true)
                    handleCloseMenu()
                },
                label: 'Cancelar venda'
            },])
    ]

    const handleCpfCnpjChange = (value: string | undefined) => {
        const newParams = new URLSearchParams(searchParams)
        if (value) {
            newParams.set('clientcpforcnpj', removeNonNumeric(value) ?? '')
        } else {
            newParams.delete('clientcpforcnpj')
        }
        setSearchParams(newParams)
    }


    useEffect(() => {
        if (isSuccess && data) {
            setCount(data.totalElements)
        }
    }, [isSuccess, data])




    return (
        <PageContentContainer>
            <TableHeader
                filterBtn
                filterBtnAction={() => setFilterModal(true)}
                mainIcon={<Add sx={{ color: theme.COLORS.YELLOW2 }} />}
                extraComponents={
                    <Search
                        querry={cpforcnpj}
                        onChange={handleCpfCnpjChange}
                        searchPlaceHolder="Cpf do cliente..."
                        type="number"
                        querrySearching={isLoading}
                    />
                }
            />
            <ContentBody>
                <ContentBody>
                    {isLoading ? (
                        <Box sx={{ position: 'relative', height: 500 }}>
                            <Spinner
                                state={isLoading}
                                size={10}
                                css={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%'
                                }}
                            />
                        </Box>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledDivDataTable>
                                        Cliente
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Vendedor
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Código
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Valor
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Desconto
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Troco
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Data
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Tipo
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Método de pagamento
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Status
                                    </StyledDivDataTable>
                                    <StyledDivDataTable>
                                        Opções
                                    </StyledDivDataTable>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.content.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 }
                                        }}
                                    >
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            <Tooltip title={row?.clientName}>
                                                <SalesTypography
                                                    fontWeight={'bold'}
                                                >
                                                    {formatBigNameSales(row?.clientName) || 'Sem nome'}
                                                </SalesTypography>
                                            </Tooltip>
                                            <SalesTypography>
                                                {formatDocument(
                                                    row?.clientCpforCnpj ||
                                                    'Sem CPF'
                                                )}
                                            </SalesTypography>
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            <Tooltip title={row?.nameCollaborator}>
                                                <SalesTypography
                                                    fontWeight={'bold'}
                                                >
                                                    {formatBigNameSales(row?.nameCollaborator) || 'Sem nome'}
                                                </SalesTypography>
                                            </Tooltip>
                                            <SalesTypography>
                                                {formatDocument(
                                                    row?.cpforcnpj ||
                                                    'Sem CPF'
                                                )}
                                            </SalesTypography>
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            <SalesTypography>
                                                {row.saleCod}
                                            </SalesTypography>
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            <SalesTypography>
                                                {formatCurrencyBR(row.amount)}
                                            </SalesTypography>
                                        </SalesCell>
                                        <SalesCell component="th" scope="row" size="small">
                                            <SalesTypography>
                                                {row.discount === null || row.discount === 0 ? '--' : formatCurrencyBR(row.discount)}
                                            </SalesTypography>
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            <SalesTypography>
                                                {row.changeValue === null
                                                    ? '--'
                                                    : formatCurrencyBR(
                                                        row.changeValue
                                                    )}
                                            </SalesTypography>
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            <SalesTypography>
                                                {formatDateBr(row.data)}
                                            </SalesTypography>
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            {row.type}
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            {row?.paymentMethod}
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            {<StyledStatus status={row.status} />}
                                        </SalesCell>
                                        <SalesCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                        >
                                            <Tooltip title="Opções">
                                                <IconButton
                                                    onClick={(event) => {
                                                        handleClickMenu(event)
                                                        handleAccessRowById(
                                                            String(row.saleId)
                                                        )
                                                        setTempSale(row)
                                                    }}
                                                >
                                                    <MoreHorizRounded />
                                                </IconButton>
                                            </Tooltip>
                                        </SalesCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}

                    <DataTablePagination
                        setPage={setPage}
                        page={page}
                        setRowsPerPage={setRowsPerPage}
                        rowsPerPage={rowsPerPage}
                        count={count}
                    />
                </ContentBody>
                <DefaultDialog
                    confirmAction={callCancelConsignedSale}
                    isOpen={openCancelConsignedSale}
                    title="Deseja realmente cancelar a venda consignada?"
                    onCloseAction={() => setOpenCancelConsignedSale(false)}
                    disabled={isCanceling}
                />
                <DefaultDialog
                    confirmAction={callCancelSale}
                    isOpen={openCancelSale}
                    title="Deseja realmente cancelar a venda?"
                    onCloseAction={() => setOpenCancelSale(false)}
                    disabled={isCanceling}
                />
                <DefaultMenu
                    anchor={anchorEl}
                    menuItems={menuItems}
                    onClose={handleCloseMenu}
                    status={open}
                />
                <NewDefaultFilter
                    isOpen={filterModal}
                    items={filterItems}
                    onClose={() => setFilterModal(false)}
                    onOpen={() => setFilterModal(true)}
                    title="Filtrar Vendas"
                    changePage={setPage}
                />
                {details && tempSale && (
                    <SalesDetailsModal
                        sale={selectedSale}
                        isOpen={details}
                        onClose={() => setDetails(false)}
                        onOpen={() => setDetails(true)}
                    />
                )}
                {tempSale && paymentConsignadoModal && (
                    <PayConsignedSale
                        isOpen={paymentConsignadoModal}
                        onClose={() => setPaymentConsignadoModal(false)}
                        onOpen={() => setPaymentConsignadoModal(true)}
                        sale={selectedSale}
                        refetch={refetch}
                    />
                )}
                {tempSale && paymentPromissoriaModal && (
                    <PayPromissorySale
                        isOpen={paymentPromissoriaModal}
                        onClose={() => setPaymentPromissoriaModal(false)}
                        onOpen={() => setPaymentPromissoriaModal(true)}
                        sale={selectedSale}
                        refetch={refetch}
                    />
                )}
            </ContentBody>
        </PageContentContainer>
    )
}

export default Sales
