import { SALES_LIST, SALES_UPDATE, SALES_SAVE, PAY_PROMISSORY_SALE, SALE_PAYMENT_METHOD, SALES_LIST_DETAILS, PAY_CONSIGNED_SALE, LIST_PAYMENT_TYPES, CANCEL_CONSIGNED_SALE, CANCEL_SALE } from "../../utils/constants";
import { IPage, IResponseBody } from "../../models/utils";
import { TSalesPageable, TSalesRegister } from "../../models/sales";
import { axiosInstance } from "../axios/axiosInstance";
import { AxiosError } from "axios";
import { Notification } from "app/components/toastNotification/toastNotification";

const getFiltedSales = async (
    salesPageable: TSalesPageable
): Promise<IPage<any>> => {
    const response = await axiosInstance.get<IResponseBody<IPage<any>>>(SALES_LIST, {
        params: {
            page: salesPageable.page,
            size: salesPageable.size,
            sort: "createdAt,desc",
            clientcpforcnpj: salesPageable?.clientcpforcnpj,
            status: salesPageable?.status,
            clientId: salesPageable?.clientId,
            salesCod: salesPageable?.salesCod,
        }
    });
    return response.data.data;
};

const getSalePaymentMethods = async (idSale: string): Promise<any> => {
    return await axiosInstance
        .get<IResponseBody<IPage<any>>>(SALE_PAYMENT_METHOD, {
            params: {
                salesId: idSale,
            }
        })
        .then((response) => {
            if (response instanceof AxiosError) {
                return response.data.errors;
            }
            return response;
        });
};

const getSaleDetails = async (idSale: string): Promise<any> => {
    return await axiosInstance
        .get<IResponseBody<IPage<any>>>(`${SALES_LIST_DETAILS}${idSale}`,
        )
        .then((response) => {
            if (response instanceof AxiosError) {
                return response.data.errors;
            }
            return response;
        });
};

const updateSales = async (updatedSales: TSalesRegister, idSales: string): Promise<any> => {
    return await axiosInstance
        .put(`${SALES_UPDATE}${idSales}`, updatedSales)
        .then((resp) => {
            return resp
        }).catch((error) => {
            return error
        })
}

const cancelConsignedSale = async (idSales: string): Promise<any> => {
    return await axiosInstance
        .post(`${CANCEL_CONSIGNED_SALE}${idSales}`)
        .then((response) => {
            if (response instanceof AxiosError) {
                return response.data.errors;
            }
            return response;
        });
};


const cancelSale = async (idSales: string): Promise<any> => {
    return await axiosInstance
        .post(`${CANCEL_SALE}${idSales}`)
        .then((response) => {
            if (response instanceof AxiosError) {
                return response.data.errors;
            }
            return response;
        });
};

const createSales = async (
    newSales: TSalesRegister
): Promise<any> => {
    return await axiosInstance
        .post(`${SALES_SAVE}`, newSales)
        .then((response) => {
            if (response instanceof AxiosError) {
                return response.data.errors;
            }
            return response;
        });
};

export type TPayments = {
    id: number
    value: number
}

const payPromissorySale = async (idSale: number, payments: any): Promise<any> => {
    return await axiosInstance
        .post(`${PAY_PROMISSORY_SALE}${idSale}`, 
           payments,
        )
        .then((response) => {
            if (response instanceof AxiosError) {
                Notification(response.data.errors, "error");
                return response.data.errors;
            }
            Notification(response.data.data, "success");

            return response;
        });
};

type ProductDTORequest = {
    productId: number;
    amount: number;
    productCharacteristcsDTOs: {
        id: number;
        amountProductCharacteristics: number;
    }[];
};

type PaymentMethodDTORequest = {
    id: number;
    value: number;
};


const payConsignedSale = async (idSale: number, productDTOs: ProductDTORequest[] | [], paymentMethodDTOList: PaymentMethodDTORequest[]): Promise<any> => {
    return await axiosInstance
        .post(`${PAY_CONSIGNED_SALE}${idSale}`, {
            productDTOs,
            paymentMethodDTOList,
        })
        .then((response) => {
            if (response instanceof AxiosError) {
                Notification(response.data.errors, "error");
                return response.data.errors;
            }
            Notification(response.data.data, "success");
            return response;
        });
};

const getListPaymentTypes = async (): Promise<any> => {
    return await axiosInstance
        .get<IResponseBody<IPage<any>>>(LIST_PAYMENT_TYPES)
        .then((response) => {
            if (response instanceof AxiosError) {
                return response.data.errors;
            }
            return response;
        });
};

export const SalesService = {
    getFiltedSales,
    updateSales,
    createSales,
    payPromissorySale,
    getSalePaymentMethods,
    getSaleDetails,
    payConsignedSale,
    getListPaymentTypes,
    cancelConsignedSale,
    cancelSale
}