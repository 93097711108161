import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import theme from "core/theme/theme";
import React, { useEffect, useState } from "react";
import { TCharacteristicsDTO } from "../../../core/models/product";
import DataTable from "../table/table/table";
import DeleteIcon from "@mui/icons-material/Delete";
import { ITableHeadCell, Order } from "../../../core/models/table";
import EditIcon from '@mui/icons-material/Edit';

interface ICharacteristicListProps {
  list: TCharacteristicsDTO[];
  rmv: (id: string) => void;
  edit?: (id: string) => void;
}

const head: ITableHeadCell[] = [
  { name: "name", label: "Tipo", align: "left" },
  { name: "description", label: "Descrição", align: "left" },
  { name: "amount", label: "Quantidade", align: "left" },
  { name: "remove", label: "Remover", align: "right" },
  { name: "actions2", label: "Editar", align: "right" },
];
function CharacteristicList({ list, rmv, edit }: ICharacteristicListProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [id, setId] = useState<string | undefined>();


  return (
    <DataTable
      head={head}
      data={list}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      accessRowById={(id: string) => {
        if (edit) {
          edit(id);

        }
      }}
      rmvFunction={(id: string) => rmv(id)}
      menu={
        <IconButton>
          <EditIcon />
        </IconButton>
      }
    />
  );
}

export default CharacteristicList;
