import React, { useEffect, useState } from 'react';
import { TCharacteristicsDTO, TProductRegister } from 'core/models/product';
import { FormikProps } from 'formik';
import DefaultModal from '../defaultModal/defaultModal';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import AsyncAutoComplete from 'app/components/assyncronusAutoComplete/AssyncAutoComplete';
import { CHARACTERISTIC_LIST } from 'core/utils/constants';
import { deepEqual } from 'core/utils/globalFunctions';

interface IEditCharacteristicsProps {
    characteristic: TCharacteristicsDTO;
    tempCharacteristic: TCharacteristicsDTO;
    formik: FormikProps<TProductRegister>;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    handleSelectCharacteristic: (selected: TCharacteristicsDTO) => void;
    handleEditCharacteristics: (updatedCharacteristic: TCharacteristicsDTO) => void;
    error: string;
}

const EditCharacteristics = (props: IEditCharacteristicsProps) => {
    const { characteristic, formik, isOpen, onClose, onOpen, error, handleEditCharacteristics } = props;
    const [editedCharacteristic, setEditedCharacteristic] = useState(characteristic);
    const [isEditable, setIsEditable] = useState(true);

    const handleConfirm = () => {
        handleEditCharacteristics(editedCharacteristic);
    };

    const handleCharacteristics = (characteristic: any) => {
        setEditedCharacteristic({ ...editedCharacteristic, name: characteristic.name, characteristcId: characteristic.id });
    };

    useEffect(() => {
        setIsEditable(deepEqual(characteristic, editedCharacteristic));
    }, [editedCharacteristic]);

    return (
        <DefaultModal
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            title="Editar característica"
            children={
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, padding: 1 }}>
                    <AsyncAutoComplete
                        selectedValue={editedCharacteristic}
                        label="Atribuir característica"
                        handleOnSelect={handleCharacteristics}
                        urlToSearch={CHARACTERISTIC_LIST}
                        sortField="name"
                        variant="standard"
                        searchParamName='name'
                    />
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                        <TextField
                            label="Descrição"
                            variant="standard"
                            name="description"
                            value={editedCharacteristic.description}
                            onChange={(e) => setEditedCharacteristic({ ...editedCharacteristic, description: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Quantidade"
                            variant="standard"
                            name="amount"
                            type="number"
                            value={editedCharacteristic.amount}
                            onChange={(e) => setEditedCharacteristic({ ...editedCharacteristic, amount: parseInt(e.target.value) })}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                    {error && (
                        <FormHelperText
                            sx={{ margin: -0.5, padding: 0, textAlign: "center" }}
                            error={true}
                        >
                            {error}
                        </FormHelperText>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, justifyContent: "center", alignItems: "center" }}>
                        <Button sx={{ width: 100 }} onClick={onClose}>Cancelar</Button>
                        <Button sx={{ width: 100 }} onClick={handleConfirm} disabled={isEditable}>Editar</Button>
                    </Box>
                </Box>
            }
        />
    );
};

export default EditCharacteristics;
