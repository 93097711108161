import {
    TSupplierBody,
    TUpdateSupplier
} from 'core/models/supplier'
import { getIn, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Box, Button, Divider, MenuItem, TextField } from '@mui/material'
import GenericTextField from 'app/components/genericTextField/GenericTextField'
import {
    deepEqual,
    formatarCEP,
    removeNonNumeric,
    statesOpitions
} from 'core/utils/globalFunctions'
import { SupplierService } from 'core/api/supplier/supplierService'
import { AxiosError } from 'axios'
import { Validations } from 'core/utils/validations'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { IPage } from 'core/models/utils'
import DefaultModal from 'app/components/modals/defaultModal/defaultModal'

interface IEditSupplierModalProps {
    isOpen: boolean
    onClose: () => void
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onOpen: () => void
    supplier: TSupplierBody
    refetch: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<IPage<TSupplierBody> | undefined, Error>>
}

const EditSupplierModal = (props: IEditSupplierModalProps) => {
    const { isOpen, onClose, onOpen, supplier, setIsOpen } = props
    const [isValidating, setValidating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const initialValues: TUpdateSupplier = {
        cpforcnpj: supplier?.cpforcnpj || '',
        nameCompany: supplier?.nameCompany || '',
        nameRepresentative: supplier?.nameRepresentative || '',
        city: supplier?.address.city || '',
        number: supplier?.address.number || '',
        road: supplier?.address.road || '',
        uf: supplier?.address.uf || '',
        cep: supplier?.address.zipCode || '',
        telephone: supplier?.contacts.telephone || '',
        cellPhone: supplier?.contacts.cellPhone || '',
        cellPhone2: supplier?.contacts.cellPhone || '',
        email: supplier?.contacts.email || ''
    }

    const callEditSupplier = async (updatedUser: TUpdateSupplier) => {
        setIsLoading(true)

        let cleanedSupplierUser: TUpdateSupplier = {
            cpforcnpj: removeNonNumeric(updatedUser.cpforcnpj) ?? '',
            nameCompany: updatedUser.nameCompany,
            nameRepresentative: updatedUser.nameRepresentative,
            city: updatedUser.city,
            number: updatedUser.number,
            road: updatedUser.road,
            uf: updatedUser.uf,
            cep: removeNonNumeric(updatedUser.cep) ?? '',
            telephone: removeNonNumeric(updatedUser.telephone) ?? '',
            cellPhone: removeNonNumeric(updatedUser.cellPhone) ?? '',
            cellPhone2: removeNonNumeric(updatedUser.cellPhone2) ?? '',
            email: updatedUser.email
        }
        if (supplier?.id) {
            SupplierService.updateSupplier(
                cleanedSupplierUser,
                String(supplier.id)
            )
                .then((resp) => {
                    if(resp.status === 200) {
                        handleResetStates()
                        setIsOpen(false)
                        props.refetch()
                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
        }
    }

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    const hasChanges = () => {
        return !deepEqual(formik.values, initialValues)
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.EditSupplierShema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            await callEditSupplier(values)
            setSubmitting(false)
        }
    })


    function getCepData(ev: any) {
        setValidating(true)
        const cep = ev?.replace(/[^0-9]/g, '')
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                    formik.setFieldError('cep', 'CEP inválido')
                    setValidating(false)
                } else {
                    setValidating(false)
                    formik.setFieldValue('road', `${data.logradouro}`)
                    formik.setFieldValue('cep', formatarCEP(formik.values.cep))
                    formik.setFieldValue('city', `${data.localidade}`)
                    formik.setFieldValue('uf', `${data.uf}`)
                    formik.setFieldValue('neighborhood', `${data.bairro}`)
                    formik.setFieldTouched('cep', false)
                }
            })
            .catch((err) => {
                formik.setFieldError('cep', 'CEP inválido')
                setValidating(false)
            })
    }

    useEffect(() => {
        hasChanges()
    }, [formik.values])

    return (
        <DefaultModal
            title="Editar fornecedor"
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            children={
                <>
                    <Box
                        sx={{
                            gap: '0.7rem',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            padding: '1rem',
                            minWidth: 370
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                flex: 1
                            }}
                        >
                            <GenericTextField<string>
                                error={!!formik.errors.cpforcnpj}
                                helperText={formik.errors.cpforcnpj}
                                small
                                name={'cpforcnpj'}
                                label={'CNPJ *'}
                                value={formik.values.cpforcnpj}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('cpforcnpj', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.nameCompany}
                                helperText={formik.errors.nameCompany}
                                small
                                name={'nameCompany'}
                                label={'Nome da compania *'}
                                value={formik.values.nameCompany}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('nameCompany', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.nameRepresentative}
                                helperText={formik.errors.nameRepresentative}
                                small
                                name={'nameRepresentative'}
                                label={'Nome do representante *'}
                                value={formik.values.nameRepresentative}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue(
                                        'nameRepresentative',
                                        e
                                    )
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.cellPhone}
                                helperText={formik.errors.cellPhone}
                                small
                                name={'cellPhone'}
                                label={'Celular primário *'}
                                value={formik.values.cellPhone}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('cellPhone', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.cellPhone2}
                                helperText={formik.errors.cellPhone2}
                                small
                                name={'cellPhone2'}
                                label={'Celular secundário'}
                                value={formik.values.cellPhone2}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('cellPhone2', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.telephone}
                                helperText={formik.errors.telephone}
                                small
                                name={'telephone'}
                                label={'Telefone'}
                                value={formik.values.telephone}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('telephone', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.email}
                                helperText={formik.errors.email}
                                small
                                name={'email'}
                                label={'Email'}
                                value={formik.values.email}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('email', e)
                                }
                            />
                            <Divider orientation="vertical" flexItem />
                        </Box>
                        
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                flex: 1
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '0.5rem'
                                }}
                            >
                                <GenericTextField<string>
                                    error={!!formik.errors.cep}
                                    helperText={formik.errors.cep}
                                    small
                                    name={'cep'}
                                    label={'CEP *'}
                                    value={formik.values.cep}
                                    props={{
                                        onChange: formik.handleChange
                                    }}
                                    onBlur={() => getCepData(formik.values.cep)}
                                    style={{ flex: 2 }}
                                    onChangeManual={(e: string) =>
                                        formik.setFieldValue('cep', e)
                                    }
                                />
                                <TextField
                                    value={formik.values.uf}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'uf',
                                            e.target.value
                                        )
                                    }
                                    disabled={isValidating}
                                    id="outlined-select-state"
                                    margin="none"
                                    select
                                    label="UF *"
                                    size="small"
                                    name="uf"
                                    style={{ flex: 1 }}
                                    error={Boolean(getIn(formik.errors, 'uf'))}
                                    helperText={getIn(formik.errors, 'uf')}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100
                                                }
                                            }
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            margin: '1px 10px -5px '
                                        }
                                    }}
                                >
                                    {statesOpitions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <GenericTextField<string>
                                error={!!formik.errors.city}
                                helperText={formik.errors.city}
                                small
                                name={'city'}
                                label={'Cidade *'}
                                value={formik.values.city}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('city', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.road}
                                helperText={formik.errors.road}
                                small
                                name={'road'}
                                label={'Rua *'}
                                value={formik.values.road}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('road', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.number}
                                helperText={formik.errors.number}
                                small
                                name={'number'}
                                label={'Número *'}
                                value={formik.values.number}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('number', e)
                                }
                            />

                        </Box>
                    </Box>
                    <Box
                        sx={{
                            gap: ' 1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: '0.4rem'
                        }}
                    >
                        <Button
                            disabled={isLoading || isValidating}
                            onClick={() => setIsOpen(false)}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={
                                !hasChanges() || isLoading || isValidating
                            }
                            onClick={() => formik.handleSubmit()}
                        >
                            Editar
                        </Button>
                    </Box>
                </>
            }
        />
    )
}

export default EditSupplierModal
