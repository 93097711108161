import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppDispatch } from "core/hooks/reduxHooks";
import { login } from "core/redux/slices/authSlice";
import { Validations } from "core/utils/validations";
import { useCallback, useState } from "react";
import { LoginContainer, LoginContentBox, LoginLogo } from "./styles";
import { useFormik } from "formik";
import { TLogin } from "core/models/user";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GenericTextField from "app/components/genericTextField/GenericTextField";
import theme from "core/theme/theme";
import { Link, useNavigate } from "react-router-dom";
import packageJson from "../../../../package.json";  // Importa o package.json


const Login = () => {
  const dispatch = useAppDispatch();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const filial = localStorage.getItem("filial");
  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const initialValues: TLogin = {
    login: "",
    password: "",
  };

  const formatFilial = (filialName: string) => {
    switch (filialName) {
      case 'campina-grande':
        return 'Campina Grande';
      case 'guarabira':
        return 'Guarabira';
      case 'joao-pessoa':
        return 'João Pessoa';
      default:
        return filialName;
    }
  };

  const handleLogin = useCallback(
    async (values: TLogin) => {
      setIsLoading(true);
      await dispatch(login(values))
        .then(() => {
          setIsLoading(false);
          navigate("/clientes");
        })
        .catch(() => setIsLoading(false));
    },
    [dispatch]
  );

  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    validationSchema: Validations.loginSchema,
    onSubmit: handleLogin,
  });

  if (filial === null || filial === undefined) {
    navigate("/selecionarFilial");
  }

  return (
    <LoginContainer
      sx={{ display: "flex" }}
      $small={isSmallScreen}
      maxWidth={isSmallScreen ? "sm" : "md"}
      disableGutters
    >
      <LoginLogo $small={isSmallScreen}></LoginLogo>

      <LoginContentBox onSubmit={formik.handleSubmit} $small={isSmallScreen}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%", marginTop: isSmallScreen ? 0 : "10px" }}>
          <Typography
            sx={{
              fontSize: "1.1pc",
              fontWeight: "bold",
            }}
          >
            ACESSAR CONTA
          </Typography>
          <Typography
          >
            Filial {formatFilial(filial!!)}
          </Typography>

        </Box>


        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
          <GenericTextField<string>
            props={{
              fullWidth: true,
              onChange: formik.handleChange,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineIcon />
                  </InputAdornment>
                ),
              },
            }}
            value={formik.values.login}
            label="Usuário"
            name="login"
            error={!!formik.errors.login}
            helperText={formik.errors.login}
            onChangeManual={(e: string) =>
              formik.setFieldValue('login', e)
          }
          />
          <GenericTextField<string>
            props={{
              type: showPassword ? "text" : "password",
              fullWidth: true,
              onChange: formik.handleChange,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            value={formik.values.password}
            label="Senha"
            name="password"
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            onChangeManual={(e: string) =>
              formik.setFieldValue('password', e)
          }
          />

        </Box>
        <Typography
          component={Link}
          to="/esqueceuSenha"
          sx={{
            fontSize: "0.8pc",
            marginRight: -20,
            marginTop: -3,
            textDecoration: "underline",
            color: theme.COLORS.YELLOW2
          }}
        >
          Esqueci minha senha
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={isLoading}
            type={"submit"}
            sx={{
              fontWeight: "bold",
              borderRadius: 1,
              position: "relative",
              height: "45px",
            }}
            fullWidth
            variant="contained"
          >
            <Typography>ACESSAR</Typography>
          </Button>
        )}
        <Typography sx={{ fontSize: "0.8pc", marginTop: 6 }}>V {packageJson.version}</Typography>
      </LoginContentBox>
    </LoginContainer>
  );
};

export default Login;
