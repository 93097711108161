import { Box } from '@mui/material'
import { FormikProps } from 'formik'
import styled from 'styled-components'
import React, { useState } from 'react'

import MainImage from './mainImage'
import MiniatureImage from './miniatureImage'
import {
    TProductEditImage,
    TProductImage,
    TProductRegister
} from 'core/models/product'

interface IProductImagesProps {
    formik: FormikProps<TProductRegister>
    imagesIds?: TProductEditImage
    setInitialImages?: React.Dispatch<React.SetStateAction<TProductEditImage>>
}

export interface IImage {
    url?: string
    key: keyof TProductImage
    id?: number
}

function ProductImages({
    formik,
    imagesIds,
    setInitialImages
}: IProductImagesProps) {

    // Estado para a URL e chave da imagem em foco
    const [imageOnFocus, setImageOnFocus] = useState<IImage>({
        url: formik.values.productImages?.imageOne,
        key: 'imageOne',
        id: imagesIds?.imageOne?.id
    })

    return (
        <Container>
            <Miniatures>
                <MiniatureImage
                    src={{
                        url: formik.values.productImages?.imageOne,
                        key: 'imageOne',
                        id: imagesIds?.imageOne?.id
                    }}
                    formik={formik}
                    changeOnFocus={setImageOnFocus}
                    focusImage={imageOnFocus}
                    setInitialImages={setInitialImages}
                />
                <MiniatureImage
                    src={{
                        url: formik.values.productImages?.imageTwo,
                        key: 'imageTwo',
                        id: imagesIds?.imageTwo?.id
                    }}
                    formik={formik}
                    changeOnFocus={setImageOnFocus}
                    focusImage={imageOnFocus}
                    setInitialImages={setInitialImages}
                />
                <MiniatureImage
                    src={{
                        url: formik.values.productImages?.imageThree,
                        key: 'imageThree',
                        id: imagesIds?.imageThree?.id
                    }}
                    formik={formik}
                    changeOnFocus={setImageOnFocus}
                    focusImage={imageOnFocus}
                    setInitialImages={setInitialImages}
                />
            </Miniatures>
            <MainImage
                src={imageOnFocus}
                formik={formik}
                changeImageOnFocus={setImageOnFocus}
                setInitialImages={setInitialImages}
            />
        </Container>
    )
}

export default ProductImages

const Container = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1rem;
`

const Miniatures = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;    
`
