import { AxiosError } from 'axios';
import { getIn, useFormik } from 'formik';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { RegisterPage, RegisterPageContent, RegisterPageHeader } from './styles';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { InfoCard, InfoCardContainer, InfoCardTitle } from 'app/components/styles';
import GenericTextField from 'app/components/genericTextField/GenericTextField';
import { Validations } from 'core/utils/validations'
import { TIndicationBody, TIndicationRegister } from 'core/models/indication';
import { IndicationService } from 'core/api/indication/indicationService';
import { indicationsOptions } from 'core/utils/globalFunctions';
import { Notification } from 'app/components/toastNotification/toastNotification';
import AsyncAutoComplete from "../../components/assyncronusAutoComplete/AssyncAutoComplete";
import { INDICATION_TYPE_LIST } from "../../../core/utils/constants";

const RegisterIndication = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isValidating, setValidating] = useState(false);
    const [indicationType, setIndicationType] = useState<number>();

    const initialValues: TIndicationRegister = {
        name: "",
        description: "",
        typeIndicationId: 0
    };

    const handleResetStates = () => {
        setIsLoading(false);
        formik.resetForm();
    };

    const handleOnSelect = (selected: TIndicationBody) => {
        formik.setFieldValue("typeIndicationId", selected.id);
    };

    const callCreateIndication = async (newIndication: TIndicationRegister) => {
        setIsLoading(true)
        let cleanedIndication: TIndicationRegister = {
            name: newIndication.name,
            description: newIndication.description,
            typeIndicationId: newIndication.typeIndicationId
        };
        IndicationService.createIndication(cleanedIndication)
            .then((resp) => {
                handleResetStates();
                navigate(-1)
            })
            .catch((err: AxiosError) => {
                Notification(String(err.message), "error")
                setIsLoading(false);
            });
    };

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.IndicationRegisterShema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            setIsLoading(true);
            await callCreateIndication(values);
            setSubmitting(false);
        },
    });

    return (
        <RegisterPage>
            <RegisterPageHeader></RegisterPageHeader>
            <RegisterPageContent>
                <Box
                    sx={{
                        gap: " 1rem",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                    }}
                >
                    <InfoCardContainer sx={{ width: 350 }}>
                        <InfoCardTitle sx={{ whiteSpace: "nowrap" }}>
                            Informações da indicação
                        </InfoCardTitle>
                        <InfoCard sx={{ gap: "0.7rem", padding: "0.5rem" }}>
                            <GenericTextField<string>
                                error={!!formik.errors.name}
                                helperText={formik.errors.name}
                                small
                                name={"name"}
                                label={"Nome *"}
                                value={formik.values.name}
                                props={{
                                    onChange: formik.handleChange,
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('name', e)
                                }
                                onBlur={() => formik.validateField('name')}
                            />
                            <AsyncAutoComplete
                                label={'Tipo de indicação *'}
                                handleOnSelect={handleOnSelect}
                                urlToSearch={INDICATION_TYPE_LIST}
                                error={formik.errors.typeIndicationId}
                                haveError={!!formik.errors.typeIndicationId}
                                sortField="indication"
                                searchParamName='indication'
                            />
                            <TextField
                                error={!!formik.errors.description}
                                helperText={formik.errors.description}
                                name="description"
                                label="Descrição *"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                multiline
                                rows={4}
                                fullWidth
                                onBlur={() => formik.validateField('description')}
                            />
                        </InfoCard>
                    </InfoCardContainer>
                </Box>
                <Box sx={{ gap: " 1rem", display: "flex", flexDirection: "row" }}>
                    <Button onClick={() => navigate("/indicacoes")} variant="outlined">
                        Voltar
                    </Button>
                    <Button disabled={isLoading} onClick={() => formik.handleSubmit()}>Cadastrar</Button>
                </Box>
            </RegisterPageContent>
        </RegisterPage>
    )
}

export default RegisterIndication