import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField
} from '@mui/material'
import {
    DateField,
    DatePicker,
    DesktopDatePicker,
    LocalizationProvider
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import GenericTextField from 'app/components/genericTextField/GenericTextField'
import {
    InfoCardContainer,
    InfoCardTitle,
    InfoCard
} from 'app/components/styles'
import { Notification } from 'app/components/toastNotification/toastNotification'
import { AxiosError } from 'axios'
import { ClientLbsService } from 'core/api/client/clientService'
import { TClientRegisterValues } from 'core/models/clientLBS'
import {
    sexoOptions,
    convertToBack,
    formatDate,
    formatarCEP,
    removeExtraSpaces,
    removeNonNumeric,
    statesOpitions,
    maritialStatusOptions
} from 'core/utils/globalFunctions'
import { Validations } from 'core/utils/validations'
import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { getIn, useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RegisterPage, RegisterPageContent, RegisterPageHeader } from './styles'
import AsyncAutoComplete from 'app/components/assyncronusAutoComplete/AssyncAutoComplete'
import { INDICATION_LIST } from 'core/utils/constants'
import { TIndicationBody } from 'core/models/indication'
import Grid2 from '@mui/material/Unstable_Grid2'
import DataPicker from 'app/components/dataPicker/datePicker'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import theme from 'core/theme/theme'

function RegisterClient() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isValidating, setValidating] = useState(false)
    dayjs.locale('pt-br')
    const initialValues: TClientRegisterValues = {
        name: '',
        rg: '',
        cpforcnpj: '',
        birthDate: '',
        maritalStatus: '',
        addressDTO: {
            zipCode: '',
            uf: '',
            road: '',
            number: '',
            neighborhood: '',
            city: ''
        },
        contactDTO: {
            telephone: '',
            cellPhone: '',
            cellPhone2: '',
            email: ''
        },
        indicationId: undefined,
        sexoID: ''
    }

    function getCepData(ev: any) {
        setValidating(true)
        const cep = ev?.replace(/[^0-9]/g, '')
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                    formik.setFieldError('addressDTO.zipCode', 'CEP inválido')
                    setValidating(false)
                } else {
                    setValidating(false)

                    formik.setFieldValue(
                        'addressDTO.zipCode',
                        formatarCEP(formik.values.addressDTO.zipCode)
                    )
                    formik.setFieldValue(
                        'addressDTO.city',
                        `${data.localidade ?? ''}`
                    )
                    formik.setFieldValue('addressDTO.uf', `${data.uf ?? ''}`)
                    formik.setFieldValue(
                        'addressDTO.road',
                        `${data.logradouro ?? ''}`
                    )
                    formik.setFieldValue(
                        'addressDTO.neighborhood',
                        `${data.bairro ?? ''}`
                    )
                    formik.setFieldTouched('addressDTO.zipCode', false)
                    formik.setFieldError('addressDTO.zipCode', '')
                }
            })
            .catch((err) => {
                formik.setFieldError('addressDTO.zipCode', 'CEP inválido')
                setValidating(false)
            })
    }

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    const handleSelectIndication = (selected: TIndicationBody) => {
        formik.setFieldValue('indicationId', selected.id)
    }

    const callCreateClient = async (newUser: any) => {
        setIsLoading(true)
        let cleanedClientUser: any = {
            name: newUser.name,
            cpforcnpj: removeNonNumeric(newUser.cpforcnpj) ?? '',
            birthDate: convertToBack(newUser.birthDate),
            rg: removeNonNumeric(newUser.rg) ?? '',
            sexoID: Number(newUser.sexoID),
            indicationId: newUser.indicationId,
            maritalStatus: newUser.maritalStatus,
            addressDTO: {
                city: newUser.addressDTO.city,
                neighborhood: newUser.addressDTO.neighborhood,
                number: newUser.addressDTO.number,
                road: newUser.addressDTO.road,
                uf: newUser.addressDTO.uf,
                zipCode: removeNonNumeric(newUser.addressDTO.zipCode) ?? ''
            },
            contactDTO: {
                email: removeExtraSpaces(newUser.contactDTO.email),
                cellPhone2: removeNonNumeric(newUser.contactDTO.cellPhone2),
                cellPhone: removeNonNumeric(newUser.contactDTO.cellPhone) ?? '',
                telephone: removeNonNumeric(newUser.contactDTO.telephone)
            }
        }
        ClientLbsService.createClient(cleanedClientUser)
            .then((resp) => {
                handleResetStates()
                navigate(-1)
            })
            .catch((err: AxiosError) => {
                Notification(String(err.message), 'error')
                setIsLoading(false)
            })
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.clientRegisterSchema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            setIsLoading(true)
            await callCreateClient(values)
            setSubmitting(false)
        }
    })

    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
        formik.values.birthDate
            ? dayjs(formik.values.birthDate, 'DD/MM/YYYY')
            : null
    )

    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date)
        formik.setFieldValue('birthDate', date ? date.format('DD/MM/YYYY') : '')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <RegisterPage>
                <RegisterPageHeader></RegisterPageHeader>
                <RegisterPageContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} sm={6} md={6}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações pessoais
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <GenericTextField<string>
                                            error={!!formik.errors.name}
                                            helperText={formik.errors.name}
                                            small
                                            name={'name'}
                                            label={'Nome completo *'}
                                            value={formik.values.name}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue('name', e)
                                            }
                                            onBlur={() =>
                                                formik.validateField('name')
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={!!formik.errors.cpforcnpj}
                                            helperText={formik.errors.cpforcnpj}
                                            small
                                            name={'cpforcnpj'}
                                            label={'CPF / CNPJ *'}
                                            value={formik.values.cpforcnpj}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'cpforcnpj',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'cpforcnpj'
                                                )
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={!!formik.errors.rg}
                                            helperText={formik.errors.rg}
                                            small
                                            name={'rg'}
                                            label={'RG *'}
                                            value={formik.values.rg}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue('rg', e)
                                            }
                                            onBlur={() =>
                                                formik.validateField('rg')
                                            }
                                        />
                                        {/* 
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt-br"
                                        >
                                            <FormControl
                                                error={Boolean(
                                                    getIn(
                                                        formik.errors,
                                                        'birthDate'
                                                    )
                                                )}
                                                fullWidth
                                            >
                                                <DateField
                                                    size="small"
                                                    variant="outlined"
                                                    label="Data de nascimento *"
                                                    value={selectedDate}
                                                    format="DD/MM/YYYY"
                                                    onChange={(event) =>
                                                        handleDateChange(event)
                                                    }
                                                    InputProps={{
                                                        error: Boolean(
                                                            getIn(
                                                                formik.errors,
                                                                'birthDate'
                                                            )
                                                        )
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            margin: '1px 10px -5px '
                                                        }
                                                    }}
                                                />
                                                {formik.errors.birthDate && (
                                                    <FormHelperText>
                                                        {
                                                            formik.errors
                                                                .birthDate
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </LocalizationProvider> */}

                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt-br"
                                        >
                                            <FormControl
                                                error={Boolean(
                                                    getIn(
                                                        formik.errors,
                                                        'birthDate'
                                                    )
                                                )}
                                                fullWidth
                                            >
                                                <DateField
                                                    size="small"
                                                    variant="outlined"
                                                    label="Data de nascimento *"
                                                    value={selectedDate}
                                                    format="DD/MM/YYYY"
                                                    InputProps= {{
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <IconButton
                                                              aria-label="toggle password visibility"
                                                              onClick={() => setDatePickerOpen(true)}
                                                              edge="end"
                                                            >
                                                              <CalendarMonthIcon sx={{color: theme.COLORS.YELLOW2}}/>
                                                            </IconButton>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    onChange={(event) =>
                                                        handleDateChange(event)
                                                    }
                                                    FormHelperTextProps={{
                                                        style: {
                                                            margin: '1px 10px -5px '
                                                        }
                                                    }}

                                                />
                                                <DataPicker
                                                    isOpen={datePickerOpen}
                                                    onClose={() =>
                                                        setDatePickerOpen(false)
                                                    }
                                                    onOpen={() =>
                                                        setDatePickerOpen(true)
                                                    }
                                                    title="Escolher Data de Nascimento"
                                                    initialDate={selectedDate}
                                                    setInitialDate={
                                                        handleDateChange
                                                    }
                                                    typeOfDatePicker="data"
                                                />
                                            </FormControl>
                                        </LocalizationProvider>

                                        {/* <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt-br"
                                        >
                                            <FormControl
                                                error={Boolean(
                                                    getIn(
                                                        formik.errors,
                                                        'birthDate'
                                                    )
                                                )}
                                                size="small"
                                                fullWidth
                                                sx={{ position: 'relative' }}
                                            >
                                                <DesktopDatePicker
                                                    label="Data de Nascimento"
                                                    format="DD/MM/YYYY"
                                                    value={
                                                        formik.values.birthDate
                                                            ? dayjs(
                                                                  formik.values
                                                                      .birthDate
                                                              )
                                                            : null
                                                    }
                                                    onChange={(
                                                        newValue: any
                                                    ) => {
                                                        formik.setFieldValue(
                                                            'birthDate',
                                                            newValue
                                                                ? newValue.format(
                                                                      'YYYY-MM-DD'
                                                                  )
                                                                : null
                                                        )
                                                    }}
                                                    minDate={dayjs(
                                                        '1950-01-01'
                                                    )}
                                                    maxDate={dayjs(
                                                        '2006-12-31'
                                                    )}
                                                    slotProps={{
                                                        popper: {
                                                            placement: 'bottom'
                                                        }
                                                    }}
                                                    slots={{
                                                        textField: (params) => (
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                variant="outlined"
                                                                error={Boolean(
                                                                    getIn(
                                                                        formik.errors,
                                                                        'birthDate'
                                                                    )
                                                                )}
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .birthDate
                                                                }
                                                                fullWidth
                                                            />
                                                        )
                                                    }}
                                                />
                                            </FormControl>
                                        </LocalizationProvider> */}

                                        <TextField
                                            value={formik.values.sexoID}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'sexoID',
                                                    e.target.value
                                                )
                                            }
                                            disabled={isValidating}
                                            id="outlined-select-state"
                                            margin="none"
                                            select
                                            label="Sexo *"
                                            size="small"
                                            name="sexoID"
                                            error={Boolean(
                                                getIn(formik.errors, 'sexoID')
                                            )}
                                            helperText={getIn(
                                                formik.errors,
                                                'sexoID'
                                            )}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 100
                                                        }
                                                    }
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                style: {
                                                    margin: '1px 10px -5px '
                                                }
                                            }}
                                        >
                                            {sexoOptions.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <TextField
                                            value={formik.values.maritalStatus}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'maritalStatus',
                                                    e.target.value
                                                )
                                            }
                                            disabled={isValidating}
                                            id="outlined-select-state"
                                            margin="none"
                                            select
                                            label="Estado civil *"
                                            size="small"
                                            name="maritalStatus"
                                            error={Boolean(
                                                getIn(
                                                    formik.errors,
                                                    'maritalStatus'
                                                )
                                            )}
                                            helperText={getIn(
                                                formik.errors,
                                                'maritalStatus'
                                            )}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 100
                                                        }
                                                    }
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                style: {
                                                    margin: '1px 10px -5px '
                                                }
                                            }}
                                        >
                                            {maritialStatusOptions.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    </InfoCard>
                                </InfoCardContainer>
                            </Grid2>
                            <Grid2 xs={12} sm={6} md={6}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações de endereço
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO
                                                    ?.zipCode
                                            }
                                            helperText={
                                                formik.errors.addressDTO
                                                    ?.zipCode
                                            }
                                            small
                                            name={'addressDTO.zipCode'}
                                            label={'CEP *'}
                                            value={
                                                formik.values.addressDTO.zipCode
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.zipCode',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                getCepData(
                                                    formik.values.addressDTO
                                                        .zipCode
                                                )
                                            }
                                        />
                                        <TextField
                                            value={formik.values.addressDTO.uf}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'addressDTO.uf',
                                                    e.target.value
                                                )
                                            }
                                            disabled={isValidating}
                                            id="outlined-select-state"
                                            margin="none"
                                            select
                                            label="UF *"
                                            size="small"
                                            name="addressDTO.uf"
                                            error={Boolean(
                                                getIn(
                                                    formik.errors,
                                                    'addressDTO.uf'
                                                )
                                            )}
                                            helperText={getIn(
                                                formik.errors,
                                                'addressDTO.uf'
                                            )}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 100
                                                        }
                                                    }
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                style: {
                                                    margin: '1px 10px -5px '
                                                }
                                            }}
                                        >
                                            {statesOpitions.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO?.city
                                            }
                                            helperText={
                                                formik.errors.addressDTO?.city
                                            }
                                            small
                                            name={'addressDTO.city'}
                                            label={'Cidade *'}
                                            value={
                                                formik.values.addressDTO.city
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.city',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'addressDTO.city'
                                                )
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO
                                                    ?.neighborhood
                                            }
                                            helperText={
                                                formik.errors.addressDTO
                                                    ?.neighborhood
                                            }
                                            small
                                            name={'addressDTO.neighborhood'}
                                            label={'Bairro *'}
                                            value={
                                                formik.values.addressDTO
                                                    .neighborhood
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.neighborhood',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'addressDTO.neighborhood'
                                                )
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO?.road
                                            }
                                            helperText={
                                                formik.errors.addressDTO?.road
                                            }
                                            small
                                            name={'addressDTO.road'}
                                            label={'Rua *'}
                                            value={
                                                formik.values.addressDTO.road
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.road',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'addressDTO.road'
                                                )
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.addressDTO
                                                    ?.number
                                            }
                                            helperText={
                                                formik.errors.addressDTO?.number
                                            }
                                            small
                                            name={'addressDTO.number'}
                                            label={'Número *'}
                                            value={
                                                formik.values.addressDTO.number
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'addressDTO.number',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'addressDTO.number'
                                                )
                                            }
                                        />
                                    </InfoCard>
                                </InfoCardContainer>
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} sm={12} md={12}>
                                <InfoCardContainer>
                                    <InfoCardTitle>
                                        Informações contato
                                    </InfoCardTitle>
                                    <InfoCard>
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO
                                                    ?.cellPhone
                                            }
                                            helperText={
                                                formik.errors.contactDTO
                                                    ?.cellPhone
                                            }
                                            small
                                            name={'contactDTO.cellPhone'}
                                            label={'Celular principal *'}
                                            value={
                                                formik.values.contactDTO
                                                    .cellPhone
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.cellPhone',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'contactDTO.cellPhone'
                                                )
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO
                                                    ?.cellPhone2
                                            }
                                            helperText={
                                                formik.errors.contactDTO
                                                    ?.cellPhone2
                                            }
                                            small
                                            name={'contactDTO.cellPhone2'}
                                            label={'Celular secundário'}
                                            value={
                                                formik.values.contactDTO
                                                    .cellPhone2!!
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.cellPhone2',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'contactDTO.cellPhone2'
                                                )
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO
                                                    ?.telephone
                                            }
                                            helperText={
                                                formik.errors.contactDTO
                                                    ?.telephone
                                            }
                                            small
                                            name={'contactDTO.telephone'}
                                            label={'Telefone'}
                                            value={
                                                formik.values.contactDTO
                                                    .telephone!!
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.telephone',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'contactDTO.telephone'
                                                )
                                            }
                                        />
                                        <GenericTextField<string>
                                            error={
                                                !!formik.errors.contactDTO
                                                    ?.email
                                            }
                                            helperText={
                                                formik.errors.contactDTO?.email
                                            }
                                            small
                                            name={'contactDTO.email'}
                                            label={'Email'}
                                            value={
                                                formik.values.contactDTO.email
                                            }
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            onChangeManual={(e: string) =>
                                                formik.setFieldValue(
                                                    'contactDTO.email',
                                                    e
                                                )
                                            }
                                            onBlur={() =>
                                                formik.validateField(
                                                    'contactDTO.email'
                                                )
                                            }
                                        />
                                    </InfoCard>
                                    <InfoCardContainer>
                                        <InfoCardTitle
                                            sx={{ whiteSpace: 'nowrap' }}
                                        >
                                            Indicação
                                        </InfoCardTitle>
                                        <InfoCard>
                                            <AsyncAutoComplete
                                                label="Atribuir indicação"
                                                handleOnSelect={
                                                    handleSelectIndication
                                                }
                                                urlToSearch={INDICATION_LIST}
                                                sortField="name"
                                                error={
                                                    formik.errors.indicationId
                                                }
                                                haveError={
                                                    !!formik.errors.indicationId
                                                }
                                                searchParamName="name"
                                                onClear={() =>
                                                    formik.setFieldValue(
                                                        'indicationId',
                                                        undefined
                                                    )
                                                }
                                            />
                                        </InfoCard>
                                    </InfoCardContainer>
                                </InfoCardContainer>
                            </Grid2>
                        </Grid2>
                    </Box>
                    <Box
                        sx={{
                            gap: ' 1rem',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Button
                            onClick={() => navigate('/clientes')}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={isLoading}
                            onClick={() => formik.handleSubmit()}
                        >
                            Cadastrar
                        </Button>
                    </Box>
                </RegisterPageContent>
            </RegisterPage>
        </LocalizationProvider>
    )
}

export default RegisterClient
